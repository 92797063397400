import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mortgage-data-visualization',
  templateUrl: './mortgage-data-visualization.component.html',
  styleUrls: ['./mortgage-data-visualization.component.scss']
})
export class MortgageDataVisualizationComponent {
  @Input() data: any;
  
  constructor() { }

}
