
<div class="amortization-table">
  <table>
    <thead>

      <tr>
        <th colspan="2"><div>Nº</div></th>
        <th class="currency"><div>Capital restant dû</div></th>
        <th class="currency"><div>Assurance</div></th>
        <th class="currency"><div>Intérêts</div></th>
        <th class="currency"><div>Capital amorti</div></th>
        <th class="currency"><div>Payment</div></th>
      </tr>
    </thead>

    <tfoot>
      <tr>
        <th colspan="2"><div>Total ({{ data.numberOfPayments | number }})</div></th>
        <th class="currency"><div>{{ data.amortizationTable[data.amortizationTable.length - 1 ].balance | currency : 'EUR' : 'symbol' : '1.2-2': 'fr-FR' }}</div></th>
        <th class="currency"><div>{{ data.totalInsurances | currency : 'EUR' : 'symbol' : '1.2-2': 'fr-FR' }}</div></th>
        <th class="currency"><div>{{ data.totalInterests | currency : 'EUR' : 'symbol' : '1.2-2': 'fr-FR' }}</div></th>
        <th class="currency"><div>{{ ( data.totalPrincipal * -1 ) | currency : 'EUR' : 'symbol' : '1.2-2': 'fr-FR' }}</div></th>
        <th class="currency"><div>{{ ( data.totalPayments * -1 ) | currency : 'EUR' : 'symbol' : '1.2-2': 'fr-FR' }}</div></th>
      </tr>
    </tfoot>

    <tbody>
      <ng-container *ngFor="let row of data.amortizationTable; let i = index;">
        <tr 
          *ngIf="i === 5 && allVisible === false && data.amortizationTable.length > 10"
          class="bordered-container"
        >
          <td colspan="7"><div class="bordered table-action" (click)="allVisible = true"><span class="bpce-icon plus table-icon"></span> Afficher les {{ data.amortizationTable.length }} lignes du tableau...</div></td>
        </tr>
        <tr 
          *ngIf="i === 5 && allVisible === true"
        >
          <td colspan="7"><div class="table-action" (click)="allVisible = false"><span class="bpce-icon moins table-icon"></span> Affichage réduit du tableau</div></td>
        </tr>
        <tr 
          [ngClass]="[ 
            i > 4 && data.amortizationTable.length - i > 5 && allVisible === false ? 'step-hidden' : '',
          ]"
        >
        <td class="dot"><div 
          [ngClass]="[ 
            row.stepIndex > 0 ? 'step-dot step-x step-' + row.stepIndex % 5 : 'step-dot'
          ]"></div></td>
        <td class="index-nb">{{ row.index }}</td>
        <td class="currency">{{ row.balance | currency : 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</td>
        <td class="currency">{{ row.insurance | currency : 'EUR' : 'symbol' : '1.2-2': 'fr-FR' }}</td>
        <td class="currency">{{ row.interest | currency : 'EUR' : 'symbol' : '1.2-2': 'fr-FR' }}</td>
        <td class="currency">{{ ( row.principal * -1 ) | currency : 'EUR' : 'symbol' : '1.2-2': 'fr-FR' }}</td>
        <td class="currency">{{ ( row.payment * -1 ) | currency : 'EUR' : 'symbol' : '1.2-2': 'fr-FR' }}</td>
        </tr>
      </ng-container>
    </tbody>
    
  </table>
</div>
