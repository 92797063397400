import { Component, OnInit } from '@angular/core';
import { flatten, unflatten } from 'flat'; // TODO: user _lodah
import loan0 from '../../test-data/loan0.js';
import loan1 from '../../test-data/loan1.js';
import loan2 from '../../test-data/loan2.js';
import loan3 from '../../test-data/loan3.js';
import loan4 from '../../test-data/loan4.js';
// import loan5 from '../../test-data/loan5.js';
import loan6 from '../../test-data/loan6.js';
import loan7 from '../../test-data/loan7.js';
import loan8 from '../../test-data/loan8.js';



@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public loans = [
    loan0,
    loan1,
    loan2,
    loan3,
    loan4,
    // loan5, // suspect
    loan6,
    loan7,
    loan8
  ];

  public assuranceString = {
    crd: 'le capital restant dû',
    ci: 'le capital initial'
  }

  constructor() { }

  ngOnInit(): void {

  }

  flatenParams(prams: any): any {
    const steps = prams.steps?.map((step) => ({ ...step, payment: (step.payment * -1) }));
    return flatten({loan0: {...prams, steps}});
  }

}
