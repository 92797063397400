import { Component, ElementRef, Input, OnChanges, AfterViewChecked, ViewChild } from '@angular/core';
import * as D3 from 'd3';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnChanges, AfterViewChecked {
  @ViewChild('d3graph') element: ElementRef;
  @Input() public data: {}[];
  @Input() public expenses: number = 0;

  private host;
  private svg;
  private margin;
  private width: number;
  private height: number;
  private xScale;
  private yScale;
  private xAxis;
  private yAxis;
  private htmlElement: HTMLElement;
  private isSetup = false;

  constructor() {
    D3.formatDefaultLocale({
      "decimal": ",",
      "thousands": "\u00a0",
      "grouping": [3],
      "currency": ["", "\u00a0€"],
      "percent": "\u202f%"
    });
  }

  ngAfterViewChecked(): void {
    this.htmlElement = this.element.nativeElement;
    this.host = D3.select(this.htmlElement);
    // window.addEventListener('resize', () => this.buildChart());
    this.setup();
  }

  public ngOnChanges(changes): void {
    if (changes.hasOwnProperty('data') && this.data && this.isSetup) {
      this.buildChart();
    }
  }

  private setup(): void {
    this.margin = {top: 24, right: 24, bottom: 60, left: 60};
    this.width = 700;
    this.height = 420;
    this.xScale = D3.scaleLinear().range([0, this.width - 100]);
    this.yScale = D3.scaleLinear().range([this.height - 50, 0]);
    this.buildChart();
    this.isSetup = true;
  }

  private buildChart(): void {
    this.host?.html('');
    this.xAxis = D3.axisBottom(this.xScale);
    this.yAxis = D3.axisLeft(this.yScale);



    this.svg = this.host.append('svg')
      .attr('class', 'responsive-graph')
      .attr('width', '100%')
      .attr('viewBox',`0 0 ${this.width} ${this.height}`)
      .attr('preserveAspectRatio','xMinYMin')
      .append('g') // first g in svg container
      .attr('transform', 'translate(72,8)');

    
    this.xScale.domain([1, this.data.length]); // 1 nb month
    const boundary = D3.extent(this.data, (d: any) => (this.expenses + d.principalAcc + d.interestAcc + d.insuranceAcc));
    this.yScale.domain([0, boundary[1]] );  // 0 -> total credit cost

    this.svg.append('g')
        .attr('class', 'x axis')
        .attr('transform', `translate(0,${ this.height - 50 })`)
        .call(this.xAxis);
    
      this.svg.append('g')
        .attr('class', 'y axis')
        .call(this.yAxis)
        .append('text')
        .attr('transform', 'rotate(-90)');

      const lineExpenses = D3.line()
        .curve(D3.curveLinear)
        .x((d: any) => this.xScale(d.index))
        .y((d: any) => this.yScale(this.expenses));

      const linePrincipal = D3.line()
        .curve(D3.curveLinear)
        .x((d: any) => this.xScale(d.index))
        .y((d: any) => this.yScale(this.expenses + d.principalAcc));

      const lineInterest = D3.line()
        .curve(D3.curveLinear)
        .x((d: any) => this.xScale(d.index))
        .y((d: any) => this.yScale(this.expenses + d.principalAcc + d.interestAcc));

      const lineInsurance = D3.line()
        .curve(D3.curveLinear)
        .x((d: any) => this.xScale(d.index))
        .y((d: any) => this.yScale(this.expenses + d.principalAcc + d.interestAcc + d.insuranceAcc));

      const lineBalance = D3.line()
        .curve(D3.curveLinear)
        .x((d: any) => this.xScale(d.index))
        .y((d: any) => this.yScale(d.balance));

      if (this.expenses > 0) {
        this.svg.append('path')
          .datum(this.data)
          .attr('class', 'line')
          .attr('d', lineExpenses)
          .style('fill', 'none')
          .style('stroke', '#051b71')
          .style('stroke-width', '1px');
      }

      this.svg.append('path')
        .datum(this.data)
        .attr('class', 'line')
        .attr('d', linePrincipal)
        .style('fill', 'none')
        .style('stroke', '#051b71')
        .style('stroke-width', '1px');

      this.svg.append('path')
        .datum(this.data)
        .attr('class', 'line')
        .attr('d', lineInterest)
        .style('fill', 'none')
        .style('stroke', '#051b71')
        .style('stroke-width', '1px');
      
      this.svg.append('path')
        .datum(this.data)
        .attr('class', 'line')
        .attr('d', lineInsurance)
        .style('fill', 'none')
        .style('stroke', '#051b71')
        .style('stroke-width', '1px');

      this.svg.append('path')
        .datum(this.data)
        .attr('class', 'line')
        .attr('d', lineBalance)
        .style('fill', 'none')
        .style('stroke', '#c3e1f2')
        .style('stroke-width', '1px');
  }
}