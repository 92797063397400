export default {
  id: 'loan 6',
  ci: 200000.00,
  crd: 200000.00,
  ti: 1.8,
  ta: 0.237,
  inMode: 'crd',
  dateFin: null,
  mois: 100,
  echeance: 2188.60,
  echeanceMoyene: 2188.5956,
  totalPayments: 218859.56,
  coutTotal: 18859.56,
  steps:[
    { numberOfPayments: 6, payment: -1000.0 },
    { numberOfPayments: 6, payment: -1000.0 }
  ],
  data:[
    {
      index: 1,
      payment: 1000,
      principal: 660.5,
      interest: 300,
      insurance: 39.5,
      scm: 0,
      balance: 199339.5
    },
    {
      index: 2,
      payment: 1000,
      principal: 661.62,
      interest: 299.01,
      insurance: 39.37,
      scm: 0,
      balance: 198677.88
    },
    {
      index: 3,
      payment: 1000,
      principal: 662.74,
      interest: 298.02,
      insurance: 39.24,
      scm: 0,
      balance: 198015.14
    },
    {
      index: 4,
      payment: 1000,
      principal: 663.87,
      interest: 297.02,
      insurance: 39.11,
      scm: 0,
      balance: 197351.27
    },
    {
      index: 5,
      payment: 1000,
      principal: 664.99,
      interest: 296.03,
      insurance: 38.98,
      scm: 0,
      balance: 196686.28
    },
    {
      index: 6,
      payment: 1000,
      principal: 666.12,
      interest: 295.03,
      insurance: 38.85,
      scm: 0,
      balance: 196020.16
    },
    {
      index: 7,
      payment: 1000,
      principal: 667.26,
      interest: 294.03,
      insurance: 38.71,
      scm: 0,
      balance: 195352.9
    },
    {
      index: 8,
      payment: 1000,
      principal: 668.39,
      interest: 293.03,
      insurance: 38.58,
      scm: 0,
      balance: 194684.51
    },
    {
      index: 9,
      payment: 1000,
      principal: 669.52,
      interest: 292.03,
      insurance: 38.45,
      scm: 0,
      balance: 194014.99
    },
    {
      index: 10,
      payment: 1000,
      principal: 670.66,
      interest: 291.02,
      insurance: 38.32,
      scm: 0,
      balance: 193344.33
    },
    {
      index: 11,
      payment: 1000,
      principal: 671.79,
      interest: 290.02,
      insurance: 38.19,
      scm: 0,
      balance: 192672.54
    },
    {
      index: 12,
      payment: 1000,
      principal: 672.94,
      interest: 289.01,
      insurance: 38.05,
      scm: 0,
      balance: 191999.6
    },
    {
      index: 13,
      payment: 2350.68,
      principal: 2024.76,
      interest: 288,
      insurance: 37.92,
      scm: 0,
      balance: 189974.84
    },
    {
      index: 14,
      payment: 2350.68,
      principal: 2028.2,
      interest: 284.96,
      insurance: 37.52,
      scm: 0,
      balance: 187946.64
    },
    {
      index: 15,
      payment: 2350.68,
      principal: 2031.64,
      interest: 281.92,
      insurance: 37.12,
      scm: 0,
      balance: 185915
    },
    {
      index: 16,
      payment: 2350.68,
      principal: 2035.09,
      interest: 278.87,
      insurance: 36.72,
      scm: 0,
      balance: 183879.91
    },
    {
      index: 17,
      payment: 2350.68,
      principal: 2038.54,
      interest: 275.82,
      insurance: 36.32,
      scm: 0,
      balance: 181841.37
    },
    {
      index: 18,
      payment: 2350.68,
      principal: 2042.01,
      interest: 272.76,
      insurance: 35.91,
      scm: 0,
      balance: 179799.36
    },
    {
      index: 19,
      payment: 2350.68,
      principal: 2045.47,
      interest: 269.7,
      insurance: 35.51,
      scm: 0,
      balance: 177753.89
    },
    {
      index: 20,
      payment: 2350.68,
      principal: 2048.94,
      interest: 266.63,
      insurance: 35.11,
      scm: 0,
      balance: 175704.95
    },
    {
      index: 21,
      payment: 2350.68,
      principal: 2052.42,
      interest: 263.56,
      insurance: 34.7,
      scm: 0,
      balance: 173652.53
    },
    {
      index: 22,
      payment: 2350.68,
      principal: 2055.9,
      interest: 260.48,
      insurance: 34.3,
      scm: 0,
      balance: 171596.63
    },
    {
      index: 23,
      payment: 2350.68,
      principal: 2059.4,
      interest: 257.39,
      insurance: 33.89,
      scm: 0,
      balance: 169537.23
    },
    {
      index: 24,
      payment: 2350.68,
      principal: 2062.89,
      interest: 254.31,
      insurance: 33.48,
      scm: 0,
      balance: 167474.34
    },
    {
      index: 25,
      payment: 2350.68,
      principal: 2066.39,
      interest: 251.21,
      insurance: 33.08,
      scm: 0,
      balance: 165407.95
    },
    {
      index: 26,
      payment: 2350.68,
      principal: 2069.9,
      interest: 248.11,
      insurance: 32.67,
      scm: 0,
      balance: 163338.05
    },
    {
      index: 27,
      payment: 2350.68,
      principal: 2073.41,
      interest: 245.01,
      insurance: 32.26,
      scm: 0,
      balance: 161264.64
    },
    {
      index: 28,
      payment: 2350.68,
      principal: 2076.93,
      interest: 241.9,
      insurance: 31.85,
      scm: 0,
      balance: 159187.71
    },
    {
      index: 29,
      payment: 2350.68,
      principal: 2080.46,
      interest: 238.78,
      insurance: 31.44,
      scm: 0,
      balance: 157107.25
    },
    {
      index: 30,
      payment: 2350.68,
      principal: 2083.99,
      interest: 235.66,
      insurance: 31.03,
      scm: 0,
      balance: 155023.26
    },
    {
      index: 31,
      payment: 2350.68,
      principal: 2087.53,
      interest: 232.53,
      insurance: 30.62,
      scm: 0,
      balance: 152935.73
    },
    {
      index: 32,
      payment: 2350.68,
      principal: 2091.08,
      interest: 229.4,
      insurance: 30.2,
      scm: 0,
      balance: 150844.65
    },
    {
      index: 33,
      payment: 2350.68,
      principal: 2094.62,
      interest: 226.27,
      insurance: 29.79,
      scm: 0,
      balance: 148750.03
    },
    {
      index: 34,
      payment: 2350.68,
      principal: 2098.17,
      interest: 223.13,
      insurance: 29.38,
      scm: 0,
      balance: 146651.86
    },
    {
      index: 35,
      payment: 2350.68,
      principal: 2101.74,
      interest: 219.98,
      insurance: 28.96,
      scm: 0,
      balance: 144550.12
    },
    {
      index: 36,
      payment: 2350.68,
      principal: 2105.3,
      interest: 216.83,
      insurance: 28.55,
      scm: 0,
      balance: 142444.82
    },
    {
      index: 37,
      payment: 2350.68,
      principal: 2108.88,
      interest: 213.67,
      insurance: 28.13,
      scm: 0,
      balance: 140335.94
    },
    {
      index: 38,
      payment: 2350.68,
      principal: 2112.46,
      interest: 210.5,
      insurance: 27.72,
      scm: 0,
      balance: 138223.48
    },
    {
      index: 39,
      payment: 2350.68,
      principal: 2116.04,
      interest: 207.34,
      insurance: 27.3,
      scm: 0,
      balance: 136107.44
    },
    {
      index: 40,
      payment: 2350.68,
      principal: 2119.64,
      interest: 204.16,
      insurance: 26.88,
      scm: 0,
      balance: 133987.8
    },
    {
      index: 41,
      payment: 2350.68,
      principal: 2123.24,
      interest: 200.98,
      insurance: 26.46,
      scm: 0,
      balance: 131864.56
    },
    {
      index: 42,
      payment: 2350.68,
      principal: 2126.84,
      interest: 197.8,
      insurance: 26.04,
      scm: 0,
      balance: 129737.72
    },
    {
      index: 43,
      payment: 2350.68,
      principal: 2130.45,
      interest: 194.61,
      insurance: 25.62,
      scm: 0,
      balance: 127607.27
    },
    {
      index: 44,
      payment: 2350.68,
      principal: 2134.07,
      interest: 191.41,
      insurance: 25.2,
      scm: 0,
      balance: 125473.2
    },
    {
      index: 45,
      payment: 2350.68,
      principal: 2137.69,
      interest: 188.21,
      insurance: 24.78,
      scm: 0,
      balance: 123335.51
    },
    {
      index: 46,
      payment: 2350.68,
      principal: 2141.32,
      interest: 185,
      insurance: 24.36,
      scm: 0,
      balance: 121194.19
    },
    {
      index: 47,
      payment: 2350.68,
      principal: 2144.95,
      interest: 181.79,
      insurance: 23.94,
      scm: 0,
      balance: 119049.24
    },
    {
      index: 48,
      payment: 2350.68,
      principal: 2148.6,
      interest: 178.57,
      insurance: 23.51,
      scm: 0,
      balance: 116900.64
    },
    {
      index: 49,
      payment: 2350.68,
      principal: 2152.24,
      interest: 175.35,
      insurance: 23.09,
      scm: 0,
      balance: 114748.4
    },
    {
      index: 50,
      payment: 2350.68,
      principal: 2155.9,
      interest: 172.12,
      insurance: 22.66,
      scm: 0,
      balance: 112592.5
    },
    {
      index: 51,
      payment: 2350.68,
      principal: 2159.55,
      interest: 168.89,
      insurance: 22.24,
      scm: 0,
      balance: 110432.95
    },
    {
      index: 52,
      payment: 2350.68,
      principal: 2163.22,
      interest: 165.65,
      insurance: 21.81,
      scm: 0,
      balance: 108269.73
    },
    {
      index: 53,
      payment: 2350.68,
      principal: 2166.9,
      interest: 162.4,
      insurance: 21.38,
      scm: 0,
      balance: 106102.83
    },
    {
      index: 54,
      payment: 2350.68,
      principal: 2170.57,
      interest: 159.15,
      insurance: 20.96,
      scm: 0,
      balance: 103932.26
    },
    {
      index: 55,
      payment: 2350.68,
      principal: 2174.25,
      interest: 155.9,
      insurance: 20.53,
      scm: 0,
      balance: 101758.01
    },
    {
      index: 56,
      payment: 2350.68,
      principal: 2177.94,
      interest: 152.64,
      insurance: 20.1,
      scm: 0,
      balance: 99580.07
    },
    {
      index: 57,
      payment: 2350.68,
      principal: 2181.64,
      interest: 149.37,
      insurance: 19.67,
      scm: 0,
      balance: 97398.43
    },
    {
      index: 58,
      payment: 2350.68,
      principal: 2185.34,
      interest: 146.1,
      insurance: 19.24,
      scm: 0,
      balance: 95213.09
    },
    {
      index: 59,
      payment: 2350.68,
      principal: 2189.06,
      interest: 142.82,
      insurance: 18.8,
      scm: 0,
      balance: 93024.03
    },
    {
      index: 60,
      payment: 2350.68,
      principal: 2192.77,
      interest: 139.54,
      insurance: 18.37,
      scm: 0,
      balance: 90831.26
    },
    {
      index: 61,
      payment: 2350.68,
      principal: 2196.49,
      interest: 136.25,
      insurance: 17.94,
      scm: 0,
      balance: 88634.77
    },
    {
      index: 62,
      payment: 2350.68,
      principal: 2200.22,
      interest: 132.95,
      insurance: 17.51,
      scm: 0,
      balance: 86434.55
    },
    {
      index: 63,
      payment: 2350.68,
      principal: 2203.96,
      interest: 129.65,
      insurance: 17.07,
      scm: 0,
      balance: 84230.59
    },
    {
      index: 64,
      payment: 2350.68,
      principal: 2207.69,
      interest: 126.35,
      insurance: 16.64,
      scm: 0,
      balance: 82022.9
    },
    {
      index: 65,
      payment: 2350.68,
      principal: 2211.45,
      interest: 123.03,
      insurance: 16.2,
      scm: 0,
      balance: 79811.45
    },
    {
      index: 66,
      payment: 2350.68,
      principal: 2215.2,
      interest: 119.72,
      insurance: 15.76,
      scm: 0,
      balance: 77596.25
    },
    {
      index: 67,
      payment: 2350.68,
      principal: 2218.96,
      interest: 116.39,
      insurance: 15.33,
      scm: 0,
      balance: 75377.29
    },
    {
      index: 68,
      payment: 2350.68,
      principal: 2222.72,
      interest: 113.07,
      insurance: 14.89,
      scm: 0,
      balance: 73154.57
    },
    {
      index: 69,
      payment: 2350.68,
      principal: 2226.5,
      interest: 109.73,
      insurance: 14.45,
      scm: 0,
      balance: 70928.07
    },
    {
      index: 70,
      payment: 2350.68,
      principal: 2230.28,
      interest: 106.39,
      insurance: 14.01,
      scm: 0,
      balance: 68697.79
    },
    {
      index: 71,
      payment: 2350.68,
      principal: 2234.06,
      interest: 103.05,
      insurance: 13.57,
      scm: 0,
      balance: 66463.73
    },
    {
      index: 72,
      payment: 2350.68,
      principal: 2237.85,
      interest: 99.7,
      insurance: 13.13,
      scm: 0,
      balance: 64225.88
    },
    {
      index: 73,
      payment: 2350.68,
      principal: 2241.66,
      interest: 96.34,
      insurance: 12.68,
      scm: 0,
      balance: 61984.22
    },
    {
      index: 74,
      payment: 2350.68,
      principal: 2245.46,
      interest: 92.98,
      insurance: 12.24,
      scm: 0,
      balance: 59738.76
    },
    {
      index: 75,
      payment: 2350.68,
      principal: 2249.27,
      interest: 89.61,
      insurance: 11.8,
      scm: 0,
      balance: 57489.49
    },
    {
      index: 76,
      payment: 2350.68,
      principal: 2253.1,
      interest: 86.23,
      insurance: 11.35,
      scm: 0,
      balance: 55236.39
    },
    {
      index: 77,
      payment: 2350.68,
      principal: 2256.92,
      interest: 82.85,
      insurance: 10.91,
      scm: 0,
      balance: 52979.47
    },
    {
      index: 78,
      payment: 2350.68,
      principal: 2260.75,
      interest: 79.47,
      insurance: 10.46,
      scm: 0,
      balance: 50718.72
    },
    {
      index: 79,
      payment: 2350.68,
      principal: 2264.58,
      interest: 76.08,
      insurance: 10.02,
      scm: 0,
      balance: 48454.14
    },
    {
      index: 80,
      payment: 2350.68,
      principal: 2268.43,
      interest: 72.68,
      insurance: 9.57,
      scm: 0,
      balance: 46185.71
    },
    {
      index: 81,
      payment: 2350.68,
      principal: 2272.28,
      interest: 69.28,
      insurance: 9.12,
      scm: 0,
      balance: 43913.43
    },
    {
      index: 82,
      payment: 2350.68,
      principal: 2276.14,
      interest: 65.87,
      insurance: 8.67,
      scm: 0,
      balance: 41637.29
    },
    {
      index: 83,
      payment: 2350.68,
      principal: 2280,
      interest: 62.46,
      insurance: 8.22,
      scm: 0,
      balance: 39357.29
    },
    {
      index: 84,
      payment: 2350.68,
      principal: 2283.87,
      interest: 59.04,
      insurance: 7.77,
      scm: 0,
      balance: 37073.42
    },
    {
      index: 85,
      payment: 2350.68,
      principal: 2287.75,
      interest: 55.61,
      insurance: 7.32,
      scm: 0,
      balance: 34785.67
    },
    {
      index: 86,
      payment: 2350.68,
      principal: 2291.63,
      interest: 52.18,
      insurance: 6.87,
      scm: 0,
      balance: 32494.04
    },
    {
      index: 87,
      payment: 2350.68,
      principal: 2295.52,
      interest: 48.74,
      insurance: 6.42,
      scm: 0,
      balance: 30198.52
    },
    {
      index: 88,
      payment: 2350.68,
      principal: 2299.42,
      interest: 45.3,
      insurance: 5.96,
      scm: 0,
      balance: 27899.1
    },
    {
      index: 89,
      payment: 2350.68,
      principal: 2303.32,
      interest: 41.85,
      insurance: 5.51,
      scm: 0,
      balance: 25595.78
    },
    {
      index: 90,
      payment: 2350.68,
      principal: 2307.23,
      interest: 38.39,
      insurance: 5.06,
      scm: 0,
      balance: 23288.55
    },
    {
      index: 91,
      payment: 2350.68,
      principal: 2311.15,
      interest: 34.93,
      insurance: 4.6,
      scm: 0,
      balance: 20977.4
    },
    {
      index: 92,
      payment: 2350.68,
      principal: 2315.07,
      interest: 31.47,
      insurance: 4.14,
      scm: 0,
      balance: 18662.33
    },
    {
      index: 93,
      payment: 2350.68,
      principal: 2319,
      interest: 27.99,
      insurance: 3.69,
      scm: 0,
      balance: 16343.33
    },
    {
      index: 94,
      payment: 2350.68,
      principal: 2322.94,
      interest: 24.51,
      insurance: 3.23,
      scm: 0,
      balance: 14020.39
    },
    {
      index: 95,
      payment: 2350.68,
      principal: 2326.88,
      interest: 21.03,
      insurance: 2.77,
      scm: 0,
      balance: 11693.51
    },
    {
      index: 96,
      payment: 2350.68,
      principal: 2330.83,
      interest: 17.54,
      insurance: 2.31,
      scm: 0,
      balance: 9362.68
    },
    {
      index: 97,
      payment: 2350.68,
      principal: 2334.79,
      interest: 14.04,
      insurance: 1.85,
      scm: 0,
      balance: 7027.89
    },
    {
      index: 98,
      payment: 2350.68,
      principal: 2338.75,
      interest: 10.54,
      insurance: 1.39,
      scm: 0,
      balance: 4689.14
    },
    {
      index: 99,
      payment: 2350.68,
      principal: 2342.72,
      interest: 7.03,
      insurance: 0.93,
      scm: 0,
      balance: 2346.42
    },
    {
      index: 100,
      payment: 2350.4,
      principal: 2346.42,
      interest: 3.52,
      insurance: 0.46,
      scm: 0,
      balance: 0
    }
  ]
}