import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, sampleTime, debounceTime, pairwise, first } from 'rxjs/operators';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { flatten, unflatten } from 'flat'; // TODO: user _lodah
// import hash from 'object-hash';
import merge from 'ts-deepmerge';
import { Mortgages, Mortgage, Step, Expense } from './mortgage.model';
import { MortgagesService } from './mortgages.service';
import { CHANGE } from './mortgages.actions';
import { defaultMortgages } from './default-mortgages';
import { addMonths } from '../../lib/date';
import loanInputsNames from './loanInputs.constant';
import '../locales/global/fr';

@Component({
  selector: 'app-simulator-form',
  templateUrl: './simulator-form.component.html',
  styleUrls: ['./simulator-form.component.scss']
})
export class SimulatorFormComponent implements OnInit {
  rateSimulator: FormGroup;
  mortgage$: Observable<Mortgages>;
  view = '';
  mortgages: Mortgages;
  data$: Observable<{}>;
  loanInputsNames = loanInputsNames;
  shiftKeyPressed = false;
  url: string;
  data = {};
  expensesCounter = 1;
  expenseMax = 3;

  // TODO external file constant
  loanTitles = {
    loan0: 'Prêt initial',
    loan1: 'Proposition concurente',
    loan2: 'Proposition alternative',
  };

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void{
    if (event.shiftKey) {
      this.shiftKeyPressed = true;
    } else {
      this.shiftKeyPressed = false;
    }
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private mortgagesService: MortgagesService,
  ) {

    this.mortgage$ = this.route.queryParams.pipe(switchMap(params => {
      return this.getMortgage(params);
    }));

    this.mortgage$.subscribe((params) => {
      this.mortgages = params;
      this.rateSimulator?.patchValue(params, { emitEvent: false });
    });

    this.rateSimulator = this.fb.group({
      loan0: this.createLoan(this.mortgages.loan0),
      loan1: this.createLoan(this.mortgages.loan1),
      loan2: this.createLoan(this.mortgages.loan2),
    });
  }

  // TODO: add type
  createLoan(p: any): FormGroup {
    return this.fb.group({
      ci: [ // _capitalInitial
        p.ci,
        [Validators.required, Validators.min(1)]
      ],
      crd: [ // _capitalRestantDu
        p.crd,
        [Validators.required, Validators.min(1)]
      ],
      ti: [ // _tauxInteret
        p.ti,
        [Validators.required, Validators.min(0)]
      ],
      ta: [ // _tauxAssurance
        p.ta,
        [Validators.required, Validators.min(0)]
      ],
      inMode: [ // _insuranceMode
        p.inMode,
      ],
      mois: [ // _mois
        p.mois,
        [Validators.required, Validators.min(1)]
      ],
      steps: this.createSteps(p.steps), // _steps
      expenses: this.createExpenses(p.expenses), // _expenses
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.view = params.get('view');
    });

    ['loan0', 'loan1', 'loan2'].forEach( loanKey => {
      this.rateSimulator.get(loanKey).valueChanges.pipe(debounceTime(200)).subscribe((value) => {
        if (this.rateSimulator.status === 'INVALID') {
          return;
        }
        const queryParams = flatten(this.rateSimulator.value);
        this.router.navigate([], {
          queryParams,
          skipLocationChange: false,
        });
        const data = {
            loanInterest: Number(value.ti / 100),
            loanInsurance: Number(value.ta / 100),
            inMode: value.inMode,
            numberOfPayments: Number(value.mois),
            amount: Number(value.ci),
            balance: Number(value.crd),
            period: 12,
            steps: value.steps.map((step: Step) => {
              return {
                numberOfPayments: Number(step.numberOfPayments),
                payment: Number(step.payment * -1 )
              };
            }),
            expenses: value.expenses.map((expense: Expense) => {
              return {
                name: expense.name,
                amount: Number(expense.amount),
              };
            })
        };
        this.data = this.mortgagesService.computeLoan(data, loanKey);
      });

      // TODO: factoriasation and type
      if (this.rateSimulator.get(loanKey).valid) {
        const value = this.rateSimulator.get(loanKey).value;
        const data = this.getFormatedData(value);
        this.data = this.mortgagesService.computeLoan(data, loanKey);
      }
    });

    this.subscribeDeep(this.rateSimulator);
  }

  getFormatedData(value: Mortgage): any {
    return {
      loanInterest: Number(value.ti / 100),
      loanInsurance: Number(value.ta / 100),
      inMode: value.inMode,
      numberOfPayments: Number(value.mois),
      amount: Number(value.ci),
      balance: Number(value.crd),
      period: 12,
      steps: value.steps.map((step: Step) => {
        return {
          numberOfPayments: Number(step.numberOfPayments),
          payment: Number(step.payment * -1 )
        };
      }),
      expenses: value.expenses.map((expense: Expense) => {
        return {
          name: expense.name,
          amount: Number(expense.amount),
        };
      }),
    };
  }

  subscribeDeep(fg: FormGroup, parentName: string | null = null): void {
    Object.keys(fg.value).map( key => {
      const item = fg.get(key);
      if (item instanceof FormGroup) {
        this.subscribeDeep(item, key);
      } else if (item instanceof FormArray){
        // console.log(item, key);
        item.valueChanges.pipe(debounceTime(500)).subscribe(value => {
          if (item.valid) {
            this.handleAction({ type: CHANGE, payload: { key, value, parentName }}, this.rateSimulator);
          }
        });
      } else {
        item.valueChanges.pipe(debounceTime(500)).subscribe(value => {
          if (item.valid) {
            this.handleAction({ type: CHANGE, payload: { key, value, parentName }}, this.rateSimulator);
          }
        });
      }
    });
  }

  handleAction({ type, payload }, form: FormGroup): void {
    const loan0Cdr = this.rateSimulator.get('loan0.crd');
    switch (type) {
      case CHANGE:
        switch (payload.parentName) {
          case 'loan0':
            switch (payload.key) {
              case 'ci':
                if (loan0Cdr.pristine === true || loan0Cdr.value > payload.value) {
                  loan0Cdr?.patchValue(payload.value, { emitEvent: true });
                  loan0Cdr?.markAsPristine();
                }
                break;
              case 'crd':
                const loan0Ci = this.rateSimulator.get('loan0.ci');
                if (loan0Ci.value < payload.value) {
                  loan0Ci?.patchValue(payload.value, { emitEvent: true });
                  loan0Ci?.markAsPristine();
                }
                // 
                const loan1Ci = this.rateSimulator.get('loan1.ci');
                if (loan1Ci.pristine === true ) {
                  loan1Ci?.patchValue(payload.value, { emitEvent: true });
                  loan1Ci?.markAsPristine();
                }
                const loan1Crd = this.rateSimulator.get('loan1.crd');
                if (loan1Crd.pristine === true ) {
                  loan1Crd?.patchValue(payload.value, { emitEvent: true });
                  loan1Crd?.markAsPristine();
                }
                const loan2Ci = this.rateSimulator.get('loan2.ci');
                if (loan2Ci.pristine === true ) {
                  loan2Ci?.patchValue(payload.value, { emitEvent: true });
                  loan2Ci?.markAsPristine();
                }
                const loan2Crd = this.rateSimulator.get('loan2.crd');
                if (loan1Crd.pristine === true ) {
                  loan2Crd?.patchValue(payload.value, { emitEvent: true });
                  loan2Crd?.markAsPristine();
                }
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }

        break;
      default:
        break;
    }
  }

  onSubmit(): void {
    console.log(this.rateSimulator);
  }

  resetLoan(loanKey: string): void {
    this.rateSimulator.get(loanKey).markAsPristine();
    this.rateSimulator.get(loanKey).markAsUntouched();
  }

  // call service
  getMortgage(params: any): Observable<Mortgages> {
    const loans = { ...unflatten(params) }
    if ('loan0' in loans && 'crd' in loans.loan0) {
      if (!('loan1' in loans)) {
        loans.loan1 = {
          ...loans.loan0,
          ci: loans.loan0.crd,
        };
      }
      if (!('loan2' in loans)) {
        loans.loan2 = {
          ...loans.loan0,
          ci: loans.loan0.crd,
        };
      }
    }
    const mortgages = merge(defaultMortgages, loans);
    return of(mortgages);
  }

  createSteps(steps = []): FormArray {
    const stepsItems = steps.map(step => {
      return this.createStep(step.numberOfPayments, step.payment);
    });
    return this.fb.array(stepsItems);
  }

  createStep( numberOfPayments: number, payment: number): FormGroup {
    return this.fb.group({
      numberOfPayments,
      payment,
    });
  }

  addStep(fa: FormArray): void {
    fa.push(this.createStep(1, 0));
  }

  removeStep(fa: FormArray, index: number): void {
    fa.removeAt(index);
  }

  createExpenses(expenses = []): FormArray {
    const expensesItems = expenses.map(expense => {
      return this.createExpense(expense.name, expense.amount);
    });
    return this.fb.array(expensesItems || expenses);
  }

  createExpense(name: string, amount: number): FormGroup {
    return this.fb.group({
      name,
      amount,
    });
  }

  addExpense(fa: FormArray): void {
    this.expensesCounter = (fa.length > this.expensesCounter ? fa.length : this.expensesCounter) + 1;
    fa.push(this.createExpense(`Frais ${this.expensesCounter}`, 0));
  }

  removeExpense(fa: FormArray, index: number): void {
    fa.removeAt(index);
  }

  remainingNumberOfPayments(loanKey: string,  numberOfPayments: number, steps: FormArray): number {
    if (!steps.controls.length) {
      return  numberOfPayments;
    }
    const result = steps.controls.reduce((acc, item) => {
      return acc - item.value. numberOfPayments;
    },  numberOfPayments);
    return result;
  }

  getFormArray(path: string) {
    return this.rateSimulator.get(path) as FormArray;
  }

  getFormGroup(path: string) {
    return this.rateSimulator.get(path) as FormGroup;
  }

  getFormControl(path: string) {
    return this.rateSimulator.get(path) as FormControl;
  }

  getFontSize(): string {
    return '16px';
  }

}
