
<div class="infoGroup">
  <div class="label">Montant des intérêts</div>
  <div class="amount">{{ data.totalInterests | currency : 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</div>
</div>

<div class="infoGroup">
  <div class="label">Montant des assurances</div>
  <div class="amount">{{ data.totalInsurances | currency : 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</div>
</div>

<div class="infoGroup"
  *ngIf="data.totalExpenses > 0"
  >
  <div class="label">Cout total des frais</div>
  <div class="amount">{{ data.totalExpenses | currency : 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</div>
</div>

<div class="infoGroup">
  <div class="label">Cout total du crédit</div>
  <div class="amount">{{ data.totalCost | currency : 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</div>
</div>

<div *ngIf="data.steps.length === 0" class="infoGroup">
  <div class="label">Échéance mensuelle</div>
  <div class="amount">{{ (data.totalPayments / data.numberOfPayments) * -1  | currency : 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</div>
</div>

<div *ngIf="data.steps.length > 0" class="infoGroup">
  <div class="label">Échéances mensuelles</div>
  <div class="amount">Afficher le tableau d'amortissement pour connaitre les échéances</div>
</div>
