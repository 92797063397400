import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AmortizationTableRow, LoansData } from './mortgage.model';
import { CHANGE } from './mortgages.actions';
import loanInputsNames from './loanInputs.constant';
import { pmt, PaymentDueTime } from '../../lib/financial';

@Injectable({ providedIn: 'root' })
export class MortgagesService {
  loanInputsNames = loanInputsNames;
  loansData: LoansData = {};

  computeLoan(
    {
      loanInterest,
      loanInsurance,
      inMode,
      numberOfPayments,
      amount,
      balance,
      period,
      steps,
      expenses = [],
    },
    loan: string,
  ): LoansData {
    const amortizationTable = this.amortizationTable({
      loanInterest,
      loanInsurance,
      inMode,
      numberOfPayments,
      amount,
      balance,
      period,
      steps,
    });
    const totalPayments = this.totalPayments(amortizationTable);
    const totalInterests = this.totalInterests(amortizationTable);
    const totalInsurances = this.totalInsurances(amortizationTable);
    const totalPrincipal = this.totalPrincipal(amortizationTable);
    const totalExpenses = this.sumByKey(expenses, 'amount');
    const totalCost = totalInterests + totalInsurances + totalExpenses;
    this.loansData = {
      ...this.loansData,
      [loan]: {
        loanInterest,
        loanInsurance,
        inMode,
        numberOfPayments,
        amount,
        balance,
        steps,
        totalPayments,
        totalCost,
        totalInterests,
        totalInsurances,
        totalPrincipal,
        totalExpenses,
        amortizationTable,
      }
    };
    return this.loansData;
  }

  getLoansData(): LoansData {
    return this.loansData;
  }

  monthlyPayment({
    loanInterest,
    loanInsurance,
    inMode,
    numberOfPayments,
    amount,
    balance = amount,
    period = 12,
  }): number {
    let result: number;
    if (inMode === 'ci') {
      const insuranceMontlyAmount = amount * loanInsurance / period;
      result = pmt(
        loanInterest / period, // 12 == montly.
        numberOfPayments, // number of payments
        balance,
      ) - insuranceMontlyAmount;
    } else {
      result = pmt(
        (loanInterest + loanInsurance) / period, // 12 == montly.
        numberOfPayments, // number of payments
        balance,
      );
    }
    return result;
  }

  totalInterests(data: AmortizationTableRow[]): number {
    return this.sumByKey(data, 'interest');
  }

  totalInsurances(data: AmortizationTableRow[]): number {
    return this.sumByKey(data, 'insurance');
  }

  totalPayments(data: AmortizationTableRow[]): number {
    return this.sumByKey(data, 'payment');
  }

  totalPrincipal(data: AmortizationTableRow[]): number {
    return this.sumByKey(data, 'principal');
  }

  totalPaymentsAlt({
    numberOfPayments,
    payment,
  }): number {
    const result = payment * numberOfPayments;
    return result;
  }

  totalInterestAlt({
    loanInterest,
    amount,
    period = 12,
    numberOfPayments,
    payment,
  }): number {
    let result = 0;
    let balance = amount;
    for (let i = 0; i < numberOfPayments; i++) {
      result = balance * (loanInterest / period);
      balance = payment - result;
    }
    return result;
  }

  sumByKey(arr: {}[] = [], key: string): number {
    return arr.reduce((acc, value) => {
      return acc + value[key];
    }, 0);
  }

  amortizationTable({
    loanInterest, // float (% / 100)
    loanInsurance, // float (% / 100)
    inMode,
    numberOfPayments,
    amount,
    balance,
    period = 12,
    steps = [], // [{ amount: , numberOfPayments:  }]
  }): AmortizationTableRow[] {
    const result = [];
    const stepRows = steps.reduce((acc, step, index) => {
      return [ ...acc, ...Array.from({length: Number(step.numberOfPayments) }, () => ({
        stepIndex: index + 1,
        payment: step.payment,
      })) ];
    }, []);
    const rows2 = [ ...stepRows, ...Array.from({length: numberOfPayments - stepRows.length }, () => ({
      stepIndex: 0,
      payment: null,
    }))];
    let outstandingBalance = balance;
    let n = 1;
    let stepPayement = null;
    let interestAcc = 0;
    let principalAcc = 0;
    let insuranceAcc = 0;
    while (n <= numberOfPayments) {
      const row = rows2[n - 1];
      const interest = Math.round((outstandingBalance * loanInterest / period) * 100) / 100;
      
      const insurance =  Math.round(
        (
          (
          inMode === 'crd'
          ? outstandingBalance : amount
          ) * loanInsurance / period
        )
        * 100) / 100;
      let payment: number;
      let principal: number;
      if (row.payment !== null) {
        payment = row.payment;
      } else {
        if (stepPayement === null) {
          if (inMode === 'crd') {
            stepPayement = pmt(
              (loanInterest + loanInsurance) / period,
              (numberOfPayments - stepRows.length),
              outstandingBalance,
              0,
              PaymentDueTime.End
            );
          } else {
            stepPayement = pmt(
              loanInterest / period,
              (numberOfPayments - stepRows.length),
              outstandingBalance,
              0,
              PaymentDueTime.End
            ) - insurance;
          }
        }
        payment = stepPayement;
      }
      principal = Math.round((payment + interest + insurance) * 100) / 100;
      if (n === numberOfPayments) {
        principal = Math.round((outstandingBalance * -1) * 100) / 100;
        payment = Math.round((outstandingBalance * -1 - interest - insurance) * 100) / 100;
      }
      interestAcc += interest;
      principalAcc -= principal;
      insuranceAcc += insurance;
      outstandingBalance += principal;
      result.push( {
          index: n,
          balance: Math.round(outstandingBalance * 100) / 100,
          interest,
          principal,
          insurance,
          payment: Math.round(payment * 100) / 100,
          stepIndex: row.stepIndex,
          interestAcc,
          principalAcc,
          insuranceAcc,
        });
      n++;
    }
    return result;
  }
}
