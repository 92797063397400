
const loanInputsNames = {
  capitalInitial: 'ci',
  capitalRestantDu: 'crd',
  tauxInteret: 'ti',
  tauxAssurance: 'ta',
  insuranceMode: 'inMode',
  mois: 'mois',
  steps: 'steps',
  expenses: 'expenses',
};

export default loanInputsNames;
