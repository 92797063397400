<div class="welcome flex flex-warp-spaced">
  <div class="box100 title">
    <h1>Renégociation de taux</h1>
    <p>Simulateur intégré</p>
  </div>

  <div class="box100 loans-trigger">
    <div
      *ngFor="let loan of loans; index as i; first as isFirst"
      class="loan-tile"
      [routerLink]="['/simulator']" 
      [queryParams]="flatenParams({
        'ci': loan.ci, 
        'crd': loan.crd,
        'ti': loan.ti,
        'ta': loan.ta,
        'inMode': loan.inMode,
        'mois': loan.mois,
        'steps': loan.steps
      })"
    >
      <div class="label">
        Prêt de {{ loan.ci | currency : 'EUR' : 'symbol' : '1.2-2' : 'fr-FR'  }} à {{ loan.ti }}% sur {{ loan.mois }} mois
        <div class="in-mode">Assurance sur {{ assuranceString[loan.inMode] }}<span *ngIf="loan.steps && loan.steps.length > 0" class="in-mode"> et {{ loan.steps.length + 1 }} Paliers</span></div>
        
      </div>
      <div class="icon-trigger">
        <span class="bpce-icon chevron-right"></span>
      </div>
    </div>

  </div>

  
</div>