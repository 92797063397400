export default {
  id: 'loan 7',
  ci: 100000.00,
  crd: 100000.00,
  ti: 1.5,
  ta: 0.0,
  inMode: 'crd',
  dateFin: null,
  mois: 60,
  echeance: 2151.49,
  echeanceMoyene: 1742.80883333333,
  totalPayments: 104568.53,
  coutTotal: 4568.53,
  steps:[
    { numberOfPayments: 14, payment: -400.0 }
  ],
  data:[
    {
      index: 1,
      payment: 400,
      principal: 275,
      interest: 125,
      insurance: 0,
      scm: 0,
      balance: 99725
    },
    {
      index: 2,
      payment: 400,
      principal: 275.34,
      interest: 124.66,
      insurance: 0,
      scm: 0,
      balance: 99449.66
    },
    {
      index: 3,
      payment: 400,
      principal: 275.69,
      interest: 124.31,
      insurance: 0,
      scm: 0,
      balance: 99173.97
    },
    {
      index: 4,
      payment: 400,
      principal: 276.03,
      interest: 123.97,
      insurance: 0,
      scm: 0,
      balance: 98897.94
    },
    {
      index: 5,
      payment: 400,
      principal: 276.38,
      interest: 123.62,
      insurance: 0,
      scm: 0,
      balance: 98621.56
    },
    {
      index: 6,
      payment: 400,
      principal: 276.72,
      interest: 123.28,
      insurance: 0,
      scm: 0,
      balance: 98344.84
    },
    {
      index: 7,
      payment: 400,
      principal: 277.07,
      interest: 122.93,
      insurance: 0,
      scm: 0,
      balance: 98067.77
    },
    {
      index: 8,
      payment: 400,
      principal: 277.42,
      interest: 122.58,
      insurance: 0,
      scm: 0,
      balance: 97790.35
    },
    {
      index: 9,
      payment: 400,
      principal: 277.76,
      interest: 122.24,
      insurance: 0,
      scm: 0,
      balance: 97512.59
    },
    {
      index: 10,
      payment: 400,
      principal: 278.11,
      interest: 121.89,
      insurance: 0,
      scm: 0,
      balance: 97234.48
    },
    {
      index: 11,
      payment: 400,
      principal: 278.46,
      interest: 121.54,
      insurance: 0,
      scm: 0,
      balance: 96956.02
    },
    {
      index: 12,
      payment: 400,
      principal: 278.8,
      interest: 121.2,
      insurance: 0,
      scm: 0,
      balance: 96677.22
    },
    {
      index: 13,
      payment: 400,
      principal: 279.15,
      interest: 120.85,
      insurance: 0,
      scm: 0,
      balance: 96398.07
    },
    {
      index: 14,
      payment: 400,
      principal: 279.5,
      interest: 120.5,
      insurance: 0,
      scm: 0,
      balance: 96118.57
    },
    {
      index: 15,
      payment: 2151.49,
      principal: 2031.34,
      interest: 120.15,
      insurance: 0,
      scm: 0,
      balance: 94087.23
    },
    {
      index: 16,
      payment: 2151.49,
      principal: 2033.88,
      interest: 117.61,
      insurance: 0,
      scm: 0,
      balance: 92053.35
    },
    {
      index: 17,
      payment: 2151.49,
      principal: 2036.42,
      interest: 115.07,
      insurance: 0,
      scm: 0,
      balance: 90016.93
    },
    {
      index: 18,
      payment: 2151.49,
      principal: 2038.97,
      interest: 112.52,
      insurance: 0,
      scm: 0,
      balance: 87977.96
    },
    {
      index: 19,
      payment: 2151.49,
      principal: 2041.52,
      interest: 109.97,
      insurance: 0,
      scm: 0,
      balance: 85936.44
    },
    {
      index: 20,
      payment: 2151.49,
      principal: 2044.07,
      interest: 107.42,
      insurance: 0,
      scm: 0,
      balance: 83892.37
    },
    {
      index: 21,
      payment: 2151.49,
      principal: 2046.62,
      interest: 104.87,
      insurance: 0,
      scm: 0,
      balance: 81845.75
    },
    {
      index: 22,
      payment: 2151.49,
      principal: 2049.18,
      interest: 102.31,
      insurance: 0,
      scm: 0,
      balance: 79796.57
    },
    {
      index: 23,
      payment: 2151.49,
      principal: 2051.74,
      interest: 99.75,
      insurance: 0,
      scm: 0,
      balance: 77744.83
    },
    {
      index: 24,
      payment: 2151.49,
      principal: 2054.31,
      interest: 97.18,
      insurance: 0,
      scm: 0,
      balance: 75690.52
    },
    {
      index: 25,
      payment: 2151.49,
      principal: 2056.88,
      interest: 94.61,
      insurance: 0,
      scm: 0,
      balance: 73633.64
    },
    {
      index: 26,
      payment: 2151.49,
      principal: 2059.45,
      interest: 92.04,
      insurance: 0,
      scm: 0,
      balance: 71574.19
    },
    {
      index: 27,
      payment: 2151.49,
      principal: 2062.02,
      interest: 89.47,
      insurance: 0,
      scm: 0,
      balance: 69512.17
    },
    {
      index: 28,
      payment: 2151.49,
      principal: 2064.6,
      interest: 86.89,
      insurance: 0,
      scm: 0,
      balance: 67447.57
    },
    {
      index: 29,
      payment: 2151.49,
      principal: 2067.18,
      interest: 84.31,
      insurance: 0,
      scm: 0,
      balance: 65380.39
    },
    {
      index: 30,
      payment: 2151.49,
      principal: 2069.76,
      interest: 81.73,
      insurance: 0,
      scm: 0,
      balance: 63310.63
    },
    {
      index: 31,
      payment: 2151.49,
      principal: 2072.35,
      interest: 79.14,
      insurance: 0,
      scm: 0,
      balance: 61238.28
    },
    {
      index: 32,
      payment: 2151.49,
      principal: 2074.94,
      interest: 76.55,
      insurance: 0,
      scm: 0,
      balance: 59163.34
    },
    {
      index: 33,
      payment: 2151.49,
      principal: 2077.54,
      interest: 73.95,
      insurance: 0,
      scm: 0,
      balance: 57085.8
    },
    {
      index: 34,
      payment: 2151.49,
      principal: 2080.13,
      interest: 71.36,
      insurance: 0,
      scm: 0,
      balance: 55005.67
    },
    {
      index: 35,
      payment: 2151.49,
      principal: 2082.73,
      interest: 68.76,
      insurance: 0,
      scm: 0,
      balance: 52922.94
    },
    {
      index: 36,
      payment: 2151.49,
      principal: 2085.34,
      interest: 66.15,
      insurance: 0,
      scm: 0,
      balance: 50837.6
    },
    {
      index: 37,
      payment: 2151.49,
      principal: 2087.94,
      interest: 63.55,
      insurance: 0,
      scm: 0,
      balance: 48749.66
    },
    {
      index: 38,
      payment: 2151.49,
      principal: 2090.55,
      interest: 60.94,
      insurance: 0,
      scm: 0,
      balance: 46659.11
    },
    {
      index: 39,
      payment: 2151.49,
      principal: 2093.17,
      interest: 58.32,
      insurance: 0,
      scm: 0,
      balance: 44565.94
    },
    {
      index: 40,
      payment: 2151.49,
      principal: 2095.78,
      interest: 55.71,
      insurance: 0,
      scm: 0,
      balance: 42470.16
    },
    {
      index: 41,
      payment: 2151.49,
      principal: 2098.4,
      interest: 53.09,
      insurance: 0,
      scm: 0,
      balance: 40371.76
    },
    {
      index: 42,
      payment: 2151.49,
      principal: 2101.03,
      interest: 50.46,
      insurance: 0,
      scm: 0,
      balance: 38270.73
    },
    {
      index: 43,
      payment: 2151.49,
      principal: 2103.65,
      interest: 47.84,
      insurance: 0,
      scm: 0,
      balance: 36167.08
    },
    {
      index: 44,
      payment: 2151.49,
      principal: 2106.28,
      interest: 45.21,
      insurance: 0,
      scm: 0,
      balance: 34060.8
    },
    {
      index: 45,
      payment: 2151.49,
      principal: 2108.91,
      interest: 42.58,
      insurance: 0,
      scm: 0,
      balance: 31951.89
    },
    {
      index: 46,
      payment: 2151.49,
      principal: 2111.55,
      interest: 39.94,
      insurance: 0,
      scm: 0,
      balance: 29840.34
    },
    {
      index: 47,
      payment: 2151.49,
      principal: 2114.19,
      interest: 37.3,
      insurance: 0,
      scm: 0,
      balance: 27726.15
    },
    {
      index: 48,
      payment: 2151.49,
      principal: 2116.83,
      interest: 34.66,
      insurance: 0,
      scm: 0,
      balance: 25609.32
    },
    {
      index: 49,
      payment: 2151.49,
      principal: 2119.48,
      interest: 32.01,
      insurance: 0,
      scm: 0,
      balance: 23489.84
    },
    {
      index: 50,
      payment: 2151.49,
      principal: 2122.13,
      interest: 29.36,
      insurance: 0,
      scm: 0,
      balance: 21367.71
    },
    {
      index: 51,
      payment: 2151.49,
      principal: 2124.78,
      interest: 26.71,
      insurance: 0,
      scm: 0,
      balance: 19242.93
    },
    {
      index: 52,
      payment: 2151.49,
      principal: 2127.44,
      interest: 24.05,
      insurance: 0,
      scm: 0,
      balance: 17115.49
    },
    {
      index: 53,
      payment: 2151.49,
      principal: 2130.1,
      interest: 21.39,
      insurance: 0,
      scm: 0,
      balance: 14985.39
    },
    {
      index: 54,
      payment: 2151.49,
      principal: 2132.76,
      interest: 18.73,
      insurance: 0,
      scm: 0,
      balance: 12852.63
    },
    {
      index: 55,
      payment: 2151.49,
      principal: 2135.42,
      interest: 16.07,
      insurance: 0,
      scm: 0,
      balance: 10717.21
    },
    {
      index: 56,
      payment: 2151.49,
      principal: 2138.09,
      interest: 13.4,
      insurance: 0,
      scm: 0,
      balance: 8579.12
    },
    {
      index: 57,
      payment: 2151.49,
      principal: 2140.77,
      interest: 10.72,
      insurance: 0,
      scm: 0,
      balance: 6438.35
    },
    {
      index: 58,
      payment: 2151.49,
      principal: 2143.44,
      interest: 8.05,
      insurance: 0,
      scm: 0,
      balance: 4294.91
    },
    {
      index: 59,
      payment: 2151.49,
      principal: 2146.12,
      interest: 5.37,
      insurance: 0,
      scm: 0,
      balance: 2148.79
    },
    {
      index: 60,
      payment: 2151.48,
      principal: 2148.79,
      interest: 2.69,
      insurance: 0,
      scm: 0,
      balance: 0
    }
  ]
}