import { Component, Input } from '@angular/core';
import { AmortizationTableRow } from '../mortgage.model';

@Component({
  selector: 'app-amortization-table',
  templateUrl: './amortization-table.component.html',
  styleUrls: ['./amortization-table.component.scss']
})
export class AmortizationTableComponent {
  @Input() data: any;
  allVisible = false;
  constructor() {}
}
