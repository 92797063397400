export default {
  id: 'loan 0',
  ci: 100000.00,
  crd: 100000.00,
  ti: 2.55,
  ta: 0.345,
  inMode: 'crd',
  dateFin: null,
  mois: 100,
  echeance: 1126.67,
  echeanceMoyene: 1126.6708,
  totalPayments: 112667.08,
  coutTotal: 12667.08,
  data:[
    {
      index: 1,
      payment: 1126.67,
      principal: 885.42,
      interest: 212.5,
      insurance: 28.75,
      scm: 0,
      balance: 99114.58
    },
    {
      index: 2,
      payment: 1126.67,
      principal: 887.55,
      interest: 210.62,
      insurance: 28.5,
      scm: 0,
      balance: 98227.03
    },
    {
      index: 3,
      payment: 1126.67,
      principal: 889.7,
      interest: 208.73,
      insurance: 28.24,
      scm: 0,
      balance: 97337.33
    },
    {
      index: 4,
      payment: 1126.67,
      principal: 891.85,
      interest: 206.84,
      insurance: 27.98,
      scm: 0,
      balance: 96445.48
    },
    {
      index: 5,
      payment: 1126.67,
      principal: 893.99,
      interest: 204.95,
      insurance: 27.73,
      scm: 0,
      balance: 95551.49
    },
    {
      index: 6,
      payment: 1126.67,
      principal: 896.15,
      interest: 203.05,
      insurance: 27.47,
      scm: 0,
      balance: 94655.34
    },
    {
      index: 7,
      payment: 1126.67,
      principal: 898.32,
      interest: 201.14,
      insurance: 27.21,
      scm: 0,
      balance: 93757.02
    },
    {
      index: 8,
      payment: 1126.67,
      principal: 900.48,
      interest: 199.23,
      insurance: 26.96,
      scm: 0,
      balance: 92856.54
    },
    {
      index: 9,
      payment: 1126.67,
      principal: 902.65,
      interest: 197.32,
      insurance: 26.7,
      scm: 0,
      balance: 91953.89
    },
    {
      index: 10,
      payment: 1126.67,
      principal: 904.83,
      interest: 195.4,
      insurance: 26.44,
      scm: 0,
      balance: 91049.06
    },
    {
      index: 11,
      payment: 1126.67,
      principal: 907.01,
      interest: 193.48,
      insurance: 26.18,
      scm: 0,
      balance: 90142.05
    },
    {
      index: 12,
      payment: 1126.67,
      principal: 909.2,
      interest: 191.55,
      insurance: 25.92,
      scm: 0,
      balance: 89232.85
    },
    {
      index: 13,
      payment: 1126.67,
      principal: 911.4,
      interest: 189.62,
      insurance: 25.65,
      scm: 0,
      balance: 88321.45
    },
    {
      index: 14,
      payment: 1126.67,
      principal: 913.6,
      interest: 187.68,
      insurance: 25.39,
      scm: 0,
      balance: 87407.85
    },
    {
      index: 15,
      payment: 1126.67,
      principal: 915.8,
      interest: 185.74,
      insurance: 25.13,
      scm: 0,
      balance: 86492.05
    },
    {
      index: 16,
      payment: 1126.67,
      principal: 918,
      interest: 183.8,
      insurance: 24.87,
      scm: 0,
      balance: 85574.05
    },
    {
      index: 17,
      payment: 1126.67,
      principal: 920.23,
      interest: 181.84,
      insurance: 24.6,
      scm: 0,
      balance: 84653.82
    },
    {
      index: 18,
      payment: 1126.67,
      principal: 922.44,
      interest: 179.89,
      insurance: 24.34,
      scm: 0,
      balance: 83731.38
    },
    {
      index: 19,
      payment: 1126.67,
      principal: 924.67,
      interest: 177.93,
      insurance: 24.07,
      scm: 0,
      balance: 82806.71
    },
    {
      index: 20,
      payment: 1126.67,
      principal: 926.9,
      interest: 175.96,
      insurance: 23.81,
      scm: 0,
      balance: 81879.81
    },
    {
      index: 21,
      payment: 1126.67,
      principal: 929.14,
      interest: 173.99,
      insurance: 23.54,
      scm: 0,
      balance: 80950.67
    },
    {
      index: 22,
      payment: 1126.67,
      principal: 931.38,
      interest: 172.02,
      insurance: 23.27,
      scm: 0,
      balance: 80019.29
    },
    {
      index: 23,
      payment: 1126.67,
      principal: 933.62,
      interest: 170.04,
      insurance: 23.01,
      scm: 0,
      balance: 79085.67
    },
    {
      index: 24,
      payment: 1126.67,
      principal: 935.87,
      interest: 168.06,
      insurance: 22.74,
      scm: 0,
      balance: 78149.8
    },
    {
      index: 25,
      payment: 1126.67,
      principal: 938.13,
      interest: 166.07,
      insurance: 22.47,
      scm: 0,
      balance: 77211.67
    },
    {
      index: 26,
      payment: 1126.67,
      principal: 940.4,
      interest: 164.07,
      insurance: 22.2,
      scm: 0,
      balance: 76271.27
    },
    {
      index: 27,
      payment: 1126.67,
      principal: 942.66,
      interest: 162.08,
      insurance: 21.93,
      scm: 0,
      balance: 75328.61
    },
    {
      index: 28,
      payment: 1126.67,
      principal: 944.94,
      interest: 160.07,
      insurance: 21.66,
      scm: 0,
      balance: 74383.67
    },
    {
      index: 29,
      payment: 1126.67,
      principal: 947.21,
      interest: 158.07,
      insurance: 21.39,
      scm: 0,
      balance: 73436.46
    },
    {
      index: 30,
      payment: 1126.67,
      principal: 949.51,
      interest: 156.05,
      insurance: 21.11,
      scm: 0,
      balance: 72486.95
    },
    {
      index: 31,
      payment: 1126.67,
      principal: 951.8,
      interest: 154.03,
      insurance: 20.84,
      scm: 0,
      balance: 71535.15
    },
    {
      index: 32,
      payment: 1126.67,
      principal: 954.09,
      interest: 152.01,
      insurance: 20.57,
      scm: 0,
      balance: 70581.06
    },
    {
      index: 33,
      payment: 1126.67,
      principal: 956.4,
      interest: 149.98,
      insurance: 20.29,
      scm: 0,
      balance: 69624.66
    },
    {
      index: 34,
      payment: 1126.67,
      principal: 958.7,
      interest: 147.95,
      insurance: 20.02,
      scm: 0,
      balance: 68665.96
    },
    {
      index: 35,
      payment: 1126.67,
      principal: 961.01,
      interest: 145.92,
      insurance: 19.74,
      scm: 0,
      balance: 67704.95
    },
    {
      index: 36,
      payment: 1126.67,
      principal: 963.33,
      interest: 143.87,
      insurance: 19.47,
      scm: 0,
      balance: 66741.62
    },
    {
      index: 37,
      payment: 1126.67,
      principal: 965.65,
      interest: 141.83,
      insurance: 19.19,
      scm: 0,
      balance: 65775.97
    },
    {
      index: 38,
      payment: 1126.67,
      principal: 967.99,
      interest: 139.77,
      insurance: 18.91,
      scm: 0,
      balance: 64807.98
    },
    {
      index: 39,
      payment: 1126.67,
      principal: 970.32,
      interest: 137.72,
      insurance: 18.63,
      scm: 0,
      balance: 63837.66
    },
    {
      index: 40,
      payment: 1126.67,
      principal: 972.66,
      interest: 135.66,
      insurance: 18.35,
      scm: 0,
      balance: 62865
    },
    {
      index: 41,
      payment: 1126.67,
      principal: 975.01,
      interest: 133.59,
      insurance: 18.07,
      scm: 0,
      balance: 61889.99
    },
    {
      index: 42,
      payment: 1126.67,
      principal: 977.36,
      interest: 131.52,
      insurance: 17.79,
      scm: 0,
      balance: 60912.63
    },
    {
      index: 43,
      payment: 1126.67,
      principal: 979.72,
      interest: 129.44,
      insurance: 17.51,
      scm: 0,
      balance: 59932.91
    },
    {
      index: 44,
      payment: 1126.67,
      principal: 982.08,
      interest: 127.36,
      insurance: 17.23,
      scm: 0,
      balance: 58950.83
    },
    {
      index: 45,
      payment: 1126.67,
      principal: 984.45,
      interest: 125.27,
      insurance: 16.95,
      scm: 0,
      balance: 57966.38
    },
    {
      index: 46,
      payment: 1126.67,
      principal: 986.82,
      interest: 123.18,
      insurance: 16.67,
      scm: 0,
      balance: 56979.56
    },
    {
      index: 47,
      payment: 1126.67,
      principal: 989.21,
      interest: 121.08,
      insurance: 16.38,
      scm: 0,
      balance: 55990.35
    },
    {
      index: 48,
      payment: 1126.67,
      principal: 991.59,
      interest: 118.98,
      insurance: 16.1,
      scm: 0,
      balance: 54998.76
    },
    {
      index: 49,
      payment: 1126.67,
      principal: 993.99,
      interest: 116.87,
      insurance: 15.81,
      scm: 0,
      balance: 54004.77
    },
    {
      index: 50,
      payment: 1126.67,
      principal: 996.38,
      interest: 114.76,
      insurance: 15.53,
      scm: 0,
      balance: 53008.39
    },
    {
      index: 51,
      payment: 1126.67,
      principal: 998.79,
      interest: 112.64,
      insurance: 15.24,
      scm: 0,
      balance: 52009.60
    },
    {
      index: 52,
      payment: 1126.67,
      principal: 1001.2,
      interest: 110.52,
      insurance: 14.95,
      scm: 0,
      balance: 51008.40
    },
    {
      index: 53,
      payment: 1126.67,
      principal: 1003.62,
      interest: 108.39,
      insurance: 14.66,
      scm: 0,
      balance: 50004.78
    },
    {
      index: 54,
      payment: 1126.67,
      principal: 1006.03,
      interest: 106.26,
      insurance: 14.38,
      scm: 0,
      balance: 48998.75
    },
    {
      index: 55,
      payment: 1126.67,
      principal: 1008.46,
      interest: 104.12,
      insurance: 14.09,
      scm: 0,
      balance: 47990.29
    },
    {
      index: 56,
      payment: 1126.67,
      principal: 1010.89,
      interest: 101.98,
      insurance: 13.8,
      scm: 0,
      balance: 46979.4
    },
    {
      index: 57,
      payment: 1126.67,
      principal: 1013.33,
      interest: 99.83,
      insurance: 13.51,
      scm: 0,
      balance: 45966.07
    },
    {
      index: 58,
      payment: 1126.67,
      principal: 1015.77,
      interest: 97.68,
      insurance: 13.22,
      scm: 0,
      balance: 44950.3
    },
    {
      index: 59,
      payment: 1126.67,
      principal: 1018.23,
      interest: 95.52,
      insurance: 12.92,
      scm: 0,
      balance: 43932.07
    },
    {
      index: 60,
      payment: 1126.67,
      principal: 1020.68,
      interest: 93.36,
      insurance: 12.63,
      scm: 0,
      balance: 42911.39
    },
    {
      index: 61,
      payment: 1126.67,
      principal: 1023.14,
      interest: 91.19,
      insurance: 12.34,
      scm: 0,
      balance: 41888.25
    },
    {
      index: 62,
      payment: 1126.67,
      principal: 1025.62,
      interest: 89.01,
      insurance: 12.04,
      scm: 0,
      balance: 40862.63
    },
    {
      index: 63,
      payment: 1126.67,
      principal: 1028.09,
      interest: 86.83,
      insurance: 11.75,
      scm: 0,
      balance: 39834.54
    },
    {
      index: 64,
      payment: 1126.67,
      principal: 1030.57,
      interest: 84.65,
      insurance: 11.45,
      scm: 0,
      balance: 38803.97
    },
    {
      index: 65,
      payment: 1126.67,
      principal: 1033.05,
      interest: 82.46,
      insurance: 11.16,
      scm: 0,
      balance: 37770.92
    },
    {
      index: 66,
      payment: 1126.67,
      principal: 1035.55,
      interest: 80.26,
      insurance: 10.86,
      scm: 0,
      balance: 36735.37
    },
    {
      index: 67,
      payment: 1126.67,
      principal: 1038.05,
      interest: 78.06,
      insurance: 10.56,
      scm: 0,
      balance: 35697.32
    },
    {
      index: 68,
      payment: 1126.67,
      principal: 1040.55,
      interest: 75.86,
      insurance: 10.26,
      scm: 0,
      balance: 34656.77
    },
    {
      index: 69,
      payment: 1126.67,
      principal: 1043.06,
      interest: 73.65,
      insurance: 9.96,
      scm: 0,
      balance: 33613.71
    },
    {
      index: 70,
      payment: 1126.67,
      principal: 1045.58,
      interest: 71.43,
      insurance: 9.66,
      scm: 0,
      balance: 32568.13
    },
    {
      index: 71,
      payment: 1126.67,
      principal: 1048.1,
      interest: 69.21,
      insurance: 9.36,
      scm: 0,
      balance: 31520.03
    },
    {
      index: 72,
      payment: 1126.67,
      principal: 1050.63,
      interest: 66.98,
      insurance: 9.06,
      scm: 0,
      balance: 30469.4
    },
    {
      index: 73,
      payment: 1126.67,
      principal: 1053.16,
      interest: 64.75,
      insurance: 8.76,
      scm: 0,
      balance: 29416.24
    },
    {
      index: 74,
      payment: 1126.67,
      principal: 1055.7,
      interest: 62.51,
      insurance: 8.46,
      scm: 0,
      balance: 28360.54
    },
    {
      index: 75,
      payment: 1126.67,
      principal: 1058.25,
      interest: 60.27,
      insurance: 8.15,
      scm: 0,
      balance: 27302.29
    },
    {
      index: 76,
      payment: 1126.67,
      principal: 1060.8,
      interest: 58.02,
      insurance: 7.85,
      scm: 0,
      balance: 26241.49
    },
    {
      index: 77,
      payment: 1126.67,
      principal: 1063.37,
      interest: 55.76,
      insurance: 7.54,
      scm: 0,
      balance: 25178.12
    },
    {
      index: 78,
      payment: 1126.67,
      principal: 1065.93,
      interest: 53.5,
      insurance: 7.24,
      scm: 0,
      balance: 24112.19
    },
    {
      index: 79,
      payment: 1126.67,
      principal: 1068.5,
      interest: 51.24,
      insurance: 6.93,
      scm: 0,
      balance: 23043.69
    },
    {
      index: 80,
      payment: 1126.67,
      principal: 1071.07,
      interest: 48.97,
      insurance: 6.63,
      scm: 0,
      balance: 21972.62
    },
    {
      index: 81,
      payment: 1126.67,
      principal: 1073.66,
      interest: 46.69,
      insurance: 6.32,
      scm: 0,
      balance: 20898.96
    },
    {
      index: 82,
      payment: 1126.67,
      principal: 1076.25,
      interest: 44.41,
      insurance: 6.01,
      scm: 0,
      balance: 19822.71
    },
    {
      index: 83,
      payment: 1126.67,
      principal: 1078.85,
      interest: 42.12,
      insurance: 5.7,
      scm: 0,
      balance: 18743.86
    },
    {
      index: 84,
      payment: 1126.67,
      principal: 1081.45,
      interest: 39.83,
      insurance: 5.39,
      scm: 0,
      balance: 17662.41
    },
    {
      index: 85,
      payment: 1126.67,
      principal: 1084.06,
      interest: 37.53,
      insurance: 5.08,
      scm: 0,
      balance: 16578.35
    },
    {
      index: 86,
      payment: 1126.67,
      principal: 1086.67,
      interest: 35.23,
      insurance: 4.77,
      scm: 0,
      balance: 15491.68
    },
    {
      index: 87,
      payment: 1126.67,
      principal: 1089.3,
      interest: 32.92,
      insurance: 4.45,
      scm: 0,
      balance: 14402.38
    },
    {
      index: 88,
      payment: 1126.67,
      principal: 1091.92,
      interest: 30.61,
      insurance: 4.14,
      scm: 0,
      balance: 13310.46
    },
    {
      index: 89,
      payment: 1126.67,
      principal: 1094.56,
      interest: 28.28,
      insurance: 3.83,
      scm: 0,
      balance: 12215.9
    },
    {
      index: 90,
      payment: 1126.67,
      principal: 1097.2,
      interest: 25.96,
      insurance: 3.51,
      scm: 0,
      balance: 11118.7
    },
    {
      index: 91,
      payment: 1126.67,
      principal: 1099.84,
      interest: 23.63,
      insurance: 3.2,
      scm: 0,
      balance: 10018.86
    },
    {
      index: 92,
      payment: 1126.67,
      principal: 1102.5,
      interest: 21.29,
      insurance: 2.88,
      scm: 0,
      balance: 8916.36
    },
    {
      index: 93,
      payment: 1126.67,
      principal: 1105.16,
      interest: 18.95,
      insurance: 2.56,
      scm: 0,
      balance: 7811.2
    },
    {
      index: 94,
      payment: 1126.67,
      principal: 1107.82,
      interest: 16.6,
      insurance: 2.25,
      scm: 0,
      balance: 6703.38
    },
    {
      index: 95,
      payment: 1126.67,
      principal: 1110.5,
      interest: 14.24,
      insurance: 1.93,
      scm: 0,
      balance: 5592.88
    },
    {
      index: 96,
      payment: 1126.67,
      principal: 1113.18,
      interest: 11.88,
      insurance: 1.61,
      scm: 0,
      balance: 4479.7
    },
    {
      index: 97,
      payment: 1126.67,
      principal: 1115.86,
      interest: 9.52,
      insurance: 1.29,
      scm: 0,
      balance: 3363.84
    },
    {
      index: 98,
      payment: 1126.67,
      principal: 1118.55,
      interest: 7.15,
      insurance: 0.97,
      scm: 0,
      balance: 2245.29
    },
    {
      index: 99,
      payment: 1126.67,
      principal: 1121.25,
      interest: 4.77,
      insurance: 0.65,
      scm: 0,
      balance: 1124.04
    },
    {
      index: 100,
      payment: 1126.75,
      principal: 1124.04,
      interest: 2.39,
      insurance: 0.32,
      scm: 0,
      balance: 0
    }
  ]
}
