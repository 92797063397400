import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { SimulatorFormComponent } from './simulator-form/simulator-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { NgFloorPipeModule } from 'angular-pipes';
import { AmortizationTableComponent } from './simulator-form/amortization-table/amortization-table.component';
import { LogPipe } from './log.pipe';
import { MortgageSummaryComponent } from './simulator-form/mortgage-summary/mortgage-summary.component';
import { MortgageDataVisualizationComponent } from './simulator-form/mortgage-data-visualization/mortgage-data-visualization.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FooterComponent } from './simulator-form/footer/footer.component';
import { LineChartComponent } from './simulator-form/line-chart/line-chart.component';
import { HeaderComponent } from './simulator-form/header/header.component';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
};

@NgModule({
  declarations: [
    AppComponent,
    SimulatorFormComponent,
    AmortizationTableComponent,
    LogPipe,
    MortgageSummaryComponent,
    MortgageDataVisualizationComponent,
    WelcomeComponent,
    FooterComponent,
    LineChartComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatTabsModule,
    NgFloorPipeModule,
  ],
  providers: [
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig}
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
