export const defaultMortgages = {
  loan0: {
    ci: 100000,
    crd: 100000,
    ti: 2.55,
    ta: 0.345,
    inMode: 'crd',
    mois: 100,
  },
  loan1: {
    ci: 100000,
    crd: 100000,
    ti: 2.55,
    ta: 0.34,
    inMode: 'crd',
    mois: 100,
  },
  loan2: {
    ci: 100000,
    crd: 100000,
    ti: 2.55,
    ta: 0.34,
    inMode: 'crd',
    mois: 100,
  },
};
