import { Component, Input, OnInit } from '@angular/core';
import { LoansData, LoanData } from '../mortgage.model';
import loanInputsNames from '../loanInputs.constant';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() 
  data: {};

  // TODO external file constant
  loanTitles = {
    loan0: 'Prêt initial',
    loan1: 'Proposition concurente',
    loan2: 'Proposition alternative',
  };

  constructor() { }

  ngOnInit(): void {

  }

  getName(key: any) {
    return this.loanTitles[key];
  }

}
