<div class="flex">
  <div class="box50">
    <h1 [routerLink]="['/']">Renégociation de taux</h1>
    <p>Simulateur intégré</p>
  </div>
  <div class="box50">
    <nav mat-tab-nav-bar mat-stretch-tabs>
      <a mat-tab-link [active]="!view || view === ''" [routerLink]="['/simulator', '']" queryParamsHandling="merge">Synthèse</a>
      <a mat-tab-link [active]="view === 'viz'" [routerLink]="['/simulator', 'viz']" queryParamsHandling="merge">Visualisation</a>
      <a mat-tab-link [active]="view === 'table'" [routerLink]="['/simulator', 'table']" queryParamsHandling="merge">Tableau d’amortissement</a>
    </nav>
  </div>
</div>
<form [formGroup]="rateSimulator" [style.fontSize.px]="getFontSize()">
  <div class="mortgage-container" 
    *ngFor="let loan of rateSimulator.controls | keyvalue; let i = index;"
    [formGroupName]="loan.key">

    <div class="box100 flex">
      <div class="box50 loan-title">
        <h2>{{ loanTitles[loan.key] }}
          <button 
          *ngIf="false && loan.key !== 'loan0' && (rateSimulator.get(loan.key).dirty || rateSimulator.get(loan.key).touched)"
          (click)="resetLoan(loan.key)"
          mat-stroked-button color="primary" 
          class="reset-icon bp-stroked-button"
          >Réinitialiser</button>
        </h2>
      </div>
    </div>

    <div class="inputs box50 flex flex-warp-spaced">

      <div class="box50">
        <mat-form-field appearance="fill" class="rs-medium-form-field-with-suffix rs-capital-initial rs-form-field-with-help">
          <mat-label>
            Capital Initial</mat-label>
          <input matInput type="number" [step]="shiftKeyPressed ? 1000 : 1" formControlName="ci" min="1.0" required
            placeholder="Montant en euro">
          <span matSuffix>€</span>
          <mat-error
            *ngIf="rateSimulator.get(loan.key + '.ci').invalid && (rateSimulator.get(loan.key + '.ci').dirty || rateSimulator.get(loan.key + '.ci').touched)">
            Doit être supérieur à 0 euro.
          </mat-error>
          <mat-hint *ngIf="rateSimulator.get(loan.key + '.ci').pristine">Valeur automatique</mat-hint>
        </mat-form-field>
      </div>

      <div class="box50 input-hint">
        <mat-form-field appearance="fill" class="rs-medium-form-field-with-suffix rs-capital-restant-du rs-form-field-with-help">
          <mat-label>Capital restant dû</mat-label>
          <input matInput type="number" [step]="shiftKeyPressed ? 1000 : 1" formControlName="crd" min="1.0" required
            placeholder="Montant en euro">
          <span matSuffix>€</span>
          <mat-error
            *ngIf="rateSimulator.get(loan.key + '.crd').invalid && (rateSimulator.get(loan.key + '.crd').dirty || rateSimulator.get(loan.key + '.crd').touched)">
            Doit être inférieur ou égal au capital initial.
          </mat-error>
          <mat-hint *ngIf="rateSimulator.get(loan.key + '.crd').pristine">Valeur automatique</mat-hint>
        </mat-form-field>
      </div>

      <div class="box50">
        <mat-form-field appearance="fill" class="rs-medium-form-field-with-suffix rs-taux-d-interet rs-form-field-with-help">
          <mat-label>Taux d'intérêt hors assurance</mat-label>
          <input matInput type="number" [step]="shiftKeyPressed ? 0.01 : 0.001" formControlName="ti" min="0.0" required
            placeholder="Taux d'intérêt hors assurance">
          <span matSuffix>%</span>
          <mat-error
            *ngIf="rateSimulator.get(loan.key + '.ti').invalid && (rateSimulator.get(loan.key + '.ti').dirty || rateSimulator.get(loan.key + '.ti').touched)">
            Doit être positif et renseigné.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="box50">
        <mat-form-field appearance="fill" class="rs-medium-form-field-with-suffix rs-duree rs-form-field-with-help">
          <mat-label>Durée</mat-label>
          <input matInput type="number" [step]="shiftKeyPressed ? 12 : 1" formControlName="mois" min="1" max="480" required  pattern="\d+">
          <span matSuffix>mois</span>
          <mat-error 
            *ngIf="rateSimulator.get(loan.key + '.mois').invalid && (rateSimulator.get(loan.key + '.mois').dirty || rateSimulator.get(loan.key + '.mois').touched)"
            >
            <ng-container
              *ngIf="rateSimulator.get(loan.key + '.mois').errors.pattern">
              Doit être un nombre entier égale ou supérieure à 1.
            </ng-container>
            <ng-container
              *ngIf="rateSimulator.get(loan.key + '.mois').errors.required">
              Doit être égale ou supérieure à 1.
            </ng-container>
          </mat-error>
          <mat-hint>
            {{ rateSimulator.get(loan.key + '.mois').value / 12 | floor | number : '1.0-0' : 'fr-FR' }} ans 
            <ng-container *ngIf="rateSimulator.get(loan.key + '.mois').value % 12 > 0">
            et {{ rateSimulator.get(loan.key + '.mois').value % 12 | number : '1.0-0' : 'fr-FR' }} mois
            </ng-container>
          </mat-hint>

        </mat-form-field>
      </div>

      <div class="box50">
        <mat-form-field appearance="fill" class="rs-medium-form-field-with-suffix rs-mode-assurance rs-form-field-with-help">
          <mat-label>Mode de l'assurance</mat-label>
          <select matNativeControl formControlName="inMode">
            <option [ngValue]="'crd'">Sur capital restant dû</option>
            <option [ngValue]="'ci'">Sur capital initial</option>
          </select>
        </mat-form-field>
      </div>

      <div class="box50">
        <mat-form-field appearance="fill" class="rs-medium-form-field-with-suffix rs-taux-assurance rs-form-field-with-help">
          <mat-label>Assurance</mat-label>
          <input matInput type="number" [step]="shiftKeyPressed ? 0.01 : 0.001" formControlName="ta" required
            placeholder="Taux d'assurance">
          <span matSuffix>%</span>
          <mat-error
            *ngIf="rateSimulator.get(loan.key + '.ta').invalid && (rateSimulator.get(loan.key + '.ta').dirty || rateSimulator.get(loan.key + '.ta').touched)">
            Le Taux d'assurance doit être renseigné.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="box100 hr"
        *ngIf="getFormArray(loan.key + '.steps').length === 0"
        ></div>

      <div class="box100 sub-title"
        *ngIf="getFormArray(loan.key + '.steps').length > 0"
        >
        <h4>Paliers</h4>
      </div>

      <div 
        class="box100 steps"
        formArrayName="steps"
        *ngFor="let step of getFormArray(loan.key + '.steps').controls; let i = index;"
      >
        <div [formGroupName]="i" class="flex flex-warp-spaced">
          
          <div class="box50 flex">

            <div [ngClass]="[ 
              'step-input-indicator step-' + ((i + 1) % 5)
            ]"></div>

            <mat-form-field appearance="fill" class="rs-medium-form-field-with-suffix rs-step-name">
              <mat-label>Montant</mat-label>
              <input matInput type="number" [step]="shiftKeyPressed ? 100 : 1" formControlName="payment" required>
              <span matSuffix>€</span>
              <mat-error
                *ngIf="step['controls']?.payment.invalid && (step['controls']?.payment.dirty || step['controls']?.payment.touched)">
                Le montant doit être renseigné.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="box50 flex">
            <mat-form-field appearance="fill" class="box80 rs-medium-form-field-with-suffix rs-step-name">
              <mat-label>Durée</mat-label>
              <input matInput type="number" [step]="shiftKeyPressed ? 10 : 1" formControlName="numberOfPayments" min="1" [max]="rateSimulator.get(loan.key + '.mois').value - 1" required  pattern="\d+">
              <span matSuffix>mois</span>
              <mat-error 
                *ngIf="step['controls']?.numberOfPayments.invalid && (step['controls']?.numberOfPayments.dirty || step['controls']?.numberOfPayments.touched)"
                >
                <ng-container
                  *ngIf="step['controls']?.numberOfPayments.errors.pattern">
                  Doit être un nombre entier.
                </ng-container>
                <ng-container
                  *ngIf="step['controls']?.numberOfPayments.errors.required">
                  Doit être un nombre égale ou supérieure à 1.
                </ng-container>
              </mat-error>
            </mat-form-field>
            
            <div class="box20 box-center bp-icon-button-shadow-container">
              <button mat-icon-button color="primary" 
              (click)="removeStep(getFormArray(loan.key + '.steps'), i)"
              class="bp-icon-button-shadow"
              aria-label="Supprimer un palier"><span class="bpce-icon moins"></span></button>
            </div>
          </div>
          
        </div>
      </div>

      <div class="box100 flex flex-warp-spaced"
      *ngIf="getFormArray(loan.key + '.steps').length > 0"
        >
        <div class="box50">

        </div>
        <div class="box50 flex">

          <mat-form-field appearance="fill" class="box80 rs-medium-form-field-with-suffix rs-form-field-with-help">
            <mat-label>Durée de la dernière échéance</mat-label>
            <input matInput type="number" 
              [value]="remainingNumberOfPayments(loan.key, rateSimulator.get(loan.key + '.mois').value, getFormArray(loan.key + '.steps'))"
              disabled
              focusable="false"
              >
            <span matSuffix>mois</span>
            <mat-hint *ngIf="rateSimulator.get(loan.key + '.ci').pristine">Valeur automatique</mat-hint>
          </mat-form-field>
          
          <div class="box20 box-center bp-icon-button-shadow-container">
            <button mat-icon-button color="primary" 
            (click)="addStep(getFormArray(loan.key + '.steps'))"
            class="bp-icon-button-shadow"
            aria-label="Ajouter un palier"><span class="bpce-icon plus"></span></button>
          </div>
        </div>
      </div>

      <div class="box100 box-center"
        *ngIf="getFormArray(loan.key + '.steps').length === 0"
      >
        <div class="add-step bp-icon-button-shadow-container">
          <button mat-button color="primary" 
          (click)="addStep(getFormArray(loan.key + '.steps'))"
          class="bp-stroked-button bp-button-full bp-icon-button">Ajouter un Palier</button>
        </div>
      </div>

      <div class="box100 hr"
        *ngIf="rateSimulator.get(loan.key + '.expenses')['controls'].length === 0"
        ></div>

      <div class="box100"
      *ngIf="rateSimulator.get(loan.key + '.expenses')['controls'].length > 0"
        >
        <h4>Charges (frais)</h4>
      </div>

      <div 
        class="box100 expenses"
        formArrayName="expenses"
        *ngFor="let expense of getFormArray(loan.key + '.expenses').controls; let i = index;"
        >
        <div [formGroupName]="i" class="flex flex-warp-spaced">
          <div class="box50">
            <mat-form-field appearance="fill" class="rs-medium-form-field-with-suffix rs-step-name">
              <mat-label>Nom</mat-label>
              <input matInput type="text" formControlName="name" maxLength="32">
            </mat-form-field>
          </div>
          <div class="box50 flex">
            <mat-form-field appearance="fill" class="box80 rs-medium-form-field-with-suffix rs-step-name">
              <mat-label>Montant</mat-label>
              <input matInput type="number" [step]="shiftKeyPressed ? 10 : 1" formControlName="amount">
              <span matSuffix>€</span>
              <mat-error
                *ngIf="getFormControl(loan.key + '.expenses.'+ i +'.amount').invalid && (getFormControl(loan.key + '.expenses.'+ i +'.amount').dirty || getFormControl(loan.key + '.expenses.'+ i +'.amount').touched)">
                Le montant doit être renseigné.
              </mat-error>
            </mat-form-field>
            <div class="box20 box-center bp-icon-button-shadow-container">
              <button mat-icon-button color="primary" 
              (click)="removeExpense(getFormArray(loan.key + '.expenses'), i)"
              class="bp-icon-button-shadow"
              aria-label="Supprimer"><span class="bpce-icon moins"></span></button>
            </div>
          </div>
          
        </div>
      </div>

      <div class="box100 box-center"
        *ngIf="getFormArray(loan.key + '.expenses').length < expenseMax"
        >
        <div class="add-expense">
          <button mat-button color="primary" 
          (click)="addExpense(getFormArray(loan.key + '.expenses'))"
          class="bp-stroked-button bp-button-full bp-icon-button" aria-label="Ajouter une charge">Ajouter une charge (frais)</button>
        </div>
      </div>

    </div>

    <div class="result box50" [ngSwitch]="view">
      <app-amortization-table 
        *ngSwitchCase="'table'"
        class="amortization-table" 
        [data]="data[loan.key]"></app-amortization-table>
      <app-mortgage-data-visualization 
        *ngSwitchCase="'viz'"
        [data]="data[loan.key]"
      >
        DataViz
      </app-mortgage-data-visualization>
      <app-mortgage-summary 
        *ngSwitchDefault
        [data]="data[loan.key]"
        ></app-mortgage-summary>
    </div>
  </div>
</form>
<app-footer [data]="data">Footer Area</app-footer>