<footer>
  <div class="page-container">
    <div><h4>Comparaison du cout total des crédits</h4></div>
    <div class="flex">
      <div *ngFor="let loan of data | keyvalue"
      class="box33">
        <div class="label">{{ loanTitles[loan.key] }}</div>
        <div class="amount">{{ loan.value['totalCost'] | currency : 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</div>
        <div 
        class="amount mat-body-2"
        *ngIf="loan.key !== 'loan0'"
        >Différence : {{ loan.value['totalCost'] - data['loan0']['totalCost'] | currency : 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</div>
      </div>
    </div>
  </div>
</footer>
