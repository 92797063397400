export default {
  id: 'loan 2',
  ci: 100000.00,
  crd: 100000.00,
  ti: 2.55,
  ta: 0.2,
  inMode: 'ci',
  dateFin: null,
  mois: 100,
  totalPayments: 112773.83,
  coutTotal: 12773.83,
  data:[
    {
      index: 1,
      payment: 1127.74,
      principal: 898.57,
      interest: 212.5,
      insurance: 16.67,
      scm: 0,
      balance: 99101.43
    },
    {
      index: 2,
      payment: 1127.74,
      principal: 900.48,
      interest: 210.59,
      insurance: 16.67,
      scm: 0,
      balance: 98200.95
    },
    {
      index: 3,
      payment: 1127.74,
      principal: 902.39,
      interest: 208.68,
      insurance: 16.67,
      scm: 0,
      balance: 97298.56
    },
    {
      index: 4,
      payment: 1127.74,
      principal: 904.31,
      interest: 206.76,
      insurance: 16.67,
      scm: 0,
      balance: 96394.25
    },
    {
      index: 5,
      payment: 1127.74,
      principal: 906.23,
      interest: 204.84,
      insurance: 16.67,
      scm: 0,
      balance: 95488.02
    },
    {
      index: 6,
      payment: 1127.74,
      principal: 908.16,
      interest: 202.91,
      insurance: 16.67,
      scm: 0,
      balance: 94579.86
    },
    {
      index: 7,
      payment: 1127.74,
      principal: 910.09,
      interest: 200.98,
      insurance: 16.67,
      scm: 0,
      balance: 93669.77
    },
    {
      index: 8,
      payment: 1127.74,
      principal: 912.02,
      interest: 199.05,
      insurance: 16.67,
      scm: 0,
      balance: 92757.75
    },
    {
      index: 9,
      payment: 1127.74,
      principal: 913.96,
      interest: 197.11,
      insurance: 16.67,
      scm: 0,
      balance: 91843.79
    },
    {
      index: 10,
      payment: 1127.74,
      principal: 915.9,
      interest: 195.17,
      insurance: 16.67,
      scm: 0,
      balance: 90927.89
    },
    {
      index: 11,
      payment: 1127.74,
      principal: 917.85,
      interest: 193.22,
      insurance: 16.67,
      scm: 0,
      balance: 90010.04
    },
    {
      index: 12,
      payment: 1127.74,
      principal: 919.8,
      interest: 191.27,
      insurance: 16.67,
      scm: 0,
      balance: 89090.24
    },
    {
      index: 13,
      payment: 1127.74,
      principal: 921.75,
      interest: 189.32,
      insurance: 16.67,
      scm: 0,
      balance: 88168.49
    },
    {
      index: 14,
      payment: 1127.74,
      principal: 923.71,
      interest: 187.36,
      insurance: 16.67,
      scm: 0,
      balance: 87244.78
    },
    {
      index: 15,
      payment: 1127.74,
      principal: 925.67,
      interest: 185.4,
      insurance: 16.67,
      scm: 0,
      balance: 86319.11
    },
    {
      index: 16,
      payment: 1127.74,
      principal: 927.64,
      interest: 183.43,
      insurance: 16.67,
      scm: 0,
      balance: 85391.47
    },
    {
      index: 17,
      payment: 1127.74,
      principal: 929.61,
      interest: 181.46,
      insurance: 16.67,
      scm: 0,
      balance: 84461.86
    },
    {
      index: 18,
      payment: 1127.74,
      principal: 931.59,
      interest: 179.48,
      insurance: 16.67,
      scm: 0,
      balance: 83530.27
    },
    {
      index: 19,
      payment: 1127.74,
      principal: 933.57,
      interest: 177.5,
      insurance: 16.67,
      scm: 0,
      balance: 82596.7
    },
    {
      index: 20,
      payment: 1127.74,
      principal: 935.55,
      interest: 175.52,
      insurance: 16.67,
      scm: 0,
      balance: 81661.15
    },
    {
      index: 21,
      payment: 1127.74,
      principal: 937.54,
      interest: 173.53,
      insurance: 16.67,
      scm: 0,
      balance: 80723.61
    },
    {
      index: 22,
      payment: 1127.74,
      principal: 939.53,
      interest: 171.54,
      insurance: 16.67,
      scm: 0,
      balance: 79784.08
    },
    {
      index: 23,
      payment: 1127.74,
      principal: 941.53,
      interest: 169.54,
      insurance: 16.67,
      scm: 0,
      balance: 78842.55
    },
    {
      index: 24,
      payment: 1127.74,
      principal: 943.53,
      interest: 167.54,
      insurance: 16.67,
      scm: 0,
      balance: 77899.02
    },
    {
      index: 25,
      payment: 1127.74,
      principal: 945.53,
      interest: 165.54,
      insurance: 16.67,
      scm: 0,
      balance: 76953.49
    },
    {
      index: 26,
      payment: 1127.74,
      principal: 947.54,
      interest: 163.53,
      insurance: 16.67,
      scm: 0,
      balance: 76005.95
    },
    {
      index: 27,
      payment: 1127.74,
      principal: 949.56,
      interest: 161.51,
      insurance: 16.67,
      scm: 0,
      balance: 75056.39
    },
    {
      index: 28,
      payment: 1127.74,
      principal: 951.58,
      interest: 159.49,
      insurance: 16.67,
      scm: 0,
      balance: 74104.81
    },
    {
      index: 29,
      payment: 1127.74,
      principal: 953.6,
      interest: 157.47,
      insurance: 16.67,
      scm: 0,
      balance: 73151.21
    },
    {
      index: 30,
      payment: 1127.74,
      principal: 955.62,
      interest: 155.45,
      insurance: 16.67,
      scm: 0,
      balance: 72195.59
    },
    {
      index: 31,
      payment: 1127.74,
      principal: 957.65,
      interest: 153.42,
      insurance: 16.67,
      scm: 0,
      balance: 71237.94
    },
    {
      index: 32,
      payment: 1127.74,
      principal: 959.69,
      interest: 151.38,
      insurance: 16.67,
      scm: 0,
      balance: 70278.25
    },
    {
      index: 33,
      payment: 1127.74,
      principal: 961.73,
      interest: 149.34,
      insurance: 16.67,
      scm: 0,
      balance: 69316.52
    },
    {
      index: 34,
      payment: 1127.74,
      principal: 963.77,
      interest: 147.3,
      insurance: 16.67,
      scm: 0,
      balance: 68352.75
    },
    {
      index: 35,
      payment: 1127.74,
      principal: 965.82,
      interest: 145.25,
      insurance: 16.67,
      scm: 0,
      balance: 67386.93
    },
    {
      index: 36,
      payment: 1127.74,
      principal: 967.87,
      interest: 143.2,
      insurance: 16.67,
      scm: 0,
      balance: 66419.06
    },
    {
      index: 37,
      payment: 1127.74,
      principal: 969.93,
      interest: 141.14,
      insurance: 16.67,
      scm: 0,
      balance: 65449.13
    },
    {
      index: 38,
      payment: 1127.74,
      principal: 971.99,
      interest: 139.08,
      insurance: 16.67,
      scm: 0,
      balance: 64477.14
    },
    {
      index: 39,
      payment: 1127.74,
      principal: 974.06,
      interest: 137.01,
      insurance: 16.67,
      scm: 0,
      balance: 63503.08
    },
    {
      index: 40,
      payment: 1127.74,
      principal: 976.13,
      interest: 134.94,
      insurance: 16.67,
      scm: 0,
      balance: 62526.95
    },
    {
      index: 41,
      payment: 1127.74,
      principal: 978.2,
      interest: 132.87,
      insurance: 16.67,
      scm: 0,
      balance: 61548.75
    },
    {
      index: 42,
      payment: 1127.74,
      principal: 980.28,
      interest: 130.79,
      insurance: 16.67,
      scm: 0,
      balance: 60568.47
    },
    {
      index: 43,
      payment: 1127.74,
      principal: 982.36,
      interest: 128.71,
      insurance: 16.67,
      scm: 0,
      balance: 59586.11
    },
    {
      index: 44,
      payment: 1127.74,
      principal: 984.45,
      interest: 126.62,
      insurance: 16.67,
      scm: 0,
      balance: 58601.66
    },
    {
      index: 45,
      payment: 1127.74,
      principal: 986.54,
      interest: 124.53,
      insurance: 16.67,
      scm: 0,
      balance: 57615.12
    },
    {
      index: 46,
      payment: 1127.74,
      principal: 988.64,
      interest: 122.43,
      insurance: 16.67,
      scm: 0,
      balance: 56626.48
    },
    {
      index: 47,
      payment: 1127.74,
      principal: 990.74,
      interest: 120.33,
      insurance: 16.67,
      scm: 0,
      balance: 55635.74
    },
    {
      index: 48,
      payment: 1127.74,
      principal: 992.84,
      interest: 118.23,
      insurance: 16.67,
      scm: 0,
      balance: 54642.9
    },
    {
      index: 49,
      payment: 1127.74,
      principal: 994.95,
      interest: 116.12,
      insurance: 16.67,
      scm: 0,
      balance: 53647.95
    },
    {
      index: 50,
      payment: 1127.74,
      principal: 997.07,
      interest: 114,
      insurance: 16.67,
      scm: 0,
      balance: 52650.88
    },
    {
      index: 51,
      payment: 1127.74,
      principal: 999.19,
      interest: 111.88,
      insurance: 16.67,
      scm: 0,
      balance: 51651.69
    },
    {
      index: 52,
      payment: 1127.74,
      principal: 1001.31,
      interest: 109.76,
      insurance: 16.67,
      scm: 0,
      balance: 50650.38
    },
    {
      index: 53,
      payment: 1127.74,
      principal: 1003.44,
      interest: 107.63,
      insurance: 16.67,
      scm: 0,
      balance: 49646.94
    },
    {
      index: 54,
      payment: 1127.74,
      principal: 1005.57,
      interest: 105.5,
      insurance: 16.67,
      scm: 0,
      balance: 48641.37
    },
    {
      index: 55,
      payment: 1127.74,
      principal: 1007.71,
      interest: 103.36,
      insurance: 16.67,
      scm: 0,
      balance: 47633.66
    },
    {
      index: 56,
      payment: 1127.74,
      principal: 1009.85,
      interest: 101.22,
      insurance: 16.67,
      scm: 0,
      balance: 46623.81
    },
    {
      index: 57,
      payment: 1127.74,
      principal: 1011.99,
      interest: 99.08,
      insurance: 16.67,
      scm: 0,
      balance: 45611.82
    },
    {
      index: 58,
      payment: 1127.74,
      principal: 1014.14,
      interest: 96.93,
      insurance: 16.67,
      scm: 0,
      balance: 44597.68
    },
    {
      index: 59,
      payment: 1127.74,
      principal: 1016.3,
      interest: 94.77,
      insurance: 16.67,
      scm: 0,
      balance: 43581.38
    },
    {
      index: 60,
      payment: 1127.74,
      principal: 1018.46,
      interest: 92.61,
      insurance: 16.67,
      scm: 0,
      balance: 42562.92
    },
    {
      index: 61,
      payment: 1127.74,
      principal: 1020.62,
      interest: 90.45,
      insurance: 16.67,
      scm: 0,
      balance: 41542.3
    },
    {
      index: 62,
      payment: 1127.74,
      principal: 1022.79,
      interest: 88.28,
      insurance: 16.67,
      scm: 0,
      balance: 40519.51
    },
    {
      index: 63,
      payment: 1127.74,
      principal: 1024.97,
      interest: 86.1,
      insurance: 16.67,
      scm: 0,
      balance: 39494.54
    },
    {
      index: 64,
      payment: 1127.74,
      principal: 1027.14,
      interest: 83.93,
      insurance: 16.67,
      scm: 0,
      balance: 38467.4
    },
    {
      index: 65,
      payment: 1127.74,
      principal: 1029.33,
      interest: 81.74,
      insurance: 16.67,
      scm: 0,
      balance: 37438.07
    },
    {
      index: 66,
      payment: 1127.74,
      principal: 1031.51,
      interest: 79.56,
      insurance: 16.67,
      scm: 0,
      balance: 36406.56
    },
    {
      index: 67,
      payment: 1127.74,
      principal: 1033.71,
      interest: 77.36,
      insurance: 16.67,
      scm: 0,
      balance: 35372.85
    },
    {
      index: 68,
      payment: 1127.74,
      principal: 1035.9,
      interest: 75.17,
      insurance: 16.67,
      scm: 0,
      balance: 34336.95
    },
    {
      index: 69,
      payment: 1127.74,
      principal: 1038.1,
      interest: 72.97,
      insurance: 16.67,
      scm: 0,
      balance: 33298.85
    },
    {
      index: 70,
      payment: 1127.74,
      principal: 1040.31,
      interest: 70.76,
      insurance: 16.67,
      scm: 0,
      balance: 32258.54
    },
    {
      index: 71,
      payment: 1127.74,
      principal: 1042.52,
      interest: 68.55,
      insurance: 16.67,
      scm: 0,
      balance: 31216.02
    },
    {
      index: 72,
      payment: 1127.74,
      principal: 1044.74,
      interest: 66.33,
      insurance: 16.67,
      scm: 0,
      balance: 30171.28
    },
    {
      index: 73,
      payment: 1127.74,
      principal: 1046.96,
      interest: 64.11,
      insurance: 16.67,
      scm: 0,
      balance: 29124.32
    },
    {
      index: 74,
      payment: 1127.74,
      principal: 1049.18,
      interest: 61.89,
      insurance: 16.67,
      scm: 0,
      balance: 28075.14
    },
    {
      index: 75,
      payment: 1127.74,
      principal: 1051.41,
      interest: 59.66,
      insurance: 16.67,
      scm: 0,
      balance: 27023.73
    },
    {
      index: 76,
      payment: 1127.74,
      principal: 1053.64,
      interest: 57.43,
      insurance: 16.67,
      scm: 0,
      balance: 25970.09
    },
    {
      index: 77,
      payment: 1127.74,
      principal: 1055.88,
      interest: 55.19,
      insurance: 16.67,
      scm: 0,
      balance: 24914.21
    },
    {
      index: 78,
      payment: 1127.74,
      principal: 1058.13,
      interest: 52.94,
      insurance: 16.67,
      scm: 0,
      balance: 23856.08
    },
    {
      index: 79,
      payment: 1127.74,
      principal: 1060.38,
      interest: 50.69,
      insurance: 16.67,
      scm: 0,
      balance: 22795.7
    },
    {
      index: 80,
      payment: 1127.74,
      principal: 1062.63,
      interest: 48.44,
      insurance: 16.67,
      scm: 0,
      balance: 21733.07
    },
    {
      index: 81,
      payment: 1127.74,
      principal: 1064.89,
      interest: 46.18,
      insurance: 16.67,
      scm: 0,
      balance: 20668.18
    },
    {
      index: 82,
      payment: 1127.74,
      principal: 1067.15,
      interest: 43.92,
      insurance: 16.67,
      scm: 0,
      balance: 19601.03
    },
    {
      index: 83,
      payment: 1127.74,
      principal: 1069.42,
      interest: 41.65,
      insurance: 16.67,
      scm: 0,
      balance: 18531.61
    },
    {
      index: 84,
      payment: 1127.74,
      principal: 1071.69,
      interest: 39.38,
      insurance: 16.67,
      scm: 0,
      balance: 17459.92
    },
    {
      index: 85,
      payment: 1127.74,
      principal: 1073.97,
      interest: 37.1,
      insurance: 16.67,
      scm: 0,
      balance: 16385.95
    },
    {
      index: 86,
      payment: 1127.74,
      principal: 1076.25,
      interest: 34.82,
      insurance: 16.67,
      scm: 0,
      balance: 15309.7
    },
    {
      index: 87,
      payment: 1127.74,
      principal: 1078.54,
      interest: 32.53,
      insurance: 16.67,
      scm: 0,
      balance: 14231.16
    },
    {
      index: 88,
      payment: 1127.74,
      principal: 1080.83,
      interest: 30.24,
      insurance: 16.67,
      scm: 0,
      balance: 13150.33
    },
    {
      index: 89,
      payment: 1127.74,
      principal: 1083.13,
      interest: 27.94,
      insurance: 16.67,
      scm: 0,
      balance: 12067.2
    },
    {
      index: 90,
      payment: 1127.74,
      principal: 1085.43,
      interest: 25.64,
      insurance: 16.67,
      scm: 0,
      balance: 10981.77
    },
    {
      index: 91,
      payment: 1127.74,
      principal: 1087.73,
      interest: 23.34,
      insurance: 16.67,
      scm: 0,
      balance: 9894.04
    },
    {
      index: 92,
      payment: 1127.74,
      principal: 1090.05,
      interest: 21.02,
      insurance: 16.67,
      scm: 0,
      balance: 8803.99
    },
    {
      index: 93,
      payment: 1127.74,
      principal: 1092.36,
      interest: 18.71,
      insurance: 16.67,
      scm: 0,
      balance: 7711.63
    },
    {
      index: 94,
      payment: 1127.74,
      principal: 1094.68,
      interest: 16.39,
      insurance: 16.67,
      scm: 0,
      balance: 6616.95
    },
    {
      index: 95,
      payment: 1127.74,
      principal: 1097.01,
      interest: 14.06,
      insurance: 16.67,
      scm: 0,
      balance: 5519.94
    },
    {
      index: 96,
      payment: 1127.74,
      principal: 1099.34,
      interest: 11.73,
      insurance: 16.67,
      scm: 0,
      balance: 4420.6
    },
    {
      index: 97,
      payment: 1127.74,
      principal: 1101.68,
      interest: 9.39,
      insurance: 16.67,
      scm: 0,
      balance: 3318.92
    },
    {
      index: 98,
      payment: 1127.74,
      principal: 1104.02,
      interest: 7.05,
      insurance: 16.67,
      scm: 0,
      balance: 2214.9
    },
    {
      index: 99,
      payment: 1127.74,
      principal: 1106.36,
      interest: 4.71,
      insurance: 16.67,
      scm: 0,
      balance: 1108.54
    },
    {
      index: 100,
      payment: 1127.57,
      principal: 1108.54,
      interest: 2.36,
      insurance: 16.67,
      scm: 0,
      balance: 0
    }
  ]
}