export default {
  id: 'loan 1',
  ci: 100000.00,
  crd: 100000.00,
  ti: 1.5,
  ta: 0.0,
  inMode: 'crd',
  dateFin: null,
  mois: 60,
  echeance: 1730.99,
  echeanceMoyene: 1730.98816666667,
  totalPayments: 103859.29,
  coutTotal: 3859.29,
  data:[
    {
      index: 1,
      payment: 1730.99,
      principal: 1605.99,
      interest: 125,
      insurance: 0,
      scm: 0,
      balance: 98394.01
    },
    {
      index: 2,
      payment: 1730.99,
      principal: 1608,
      interest: 122.99,
      insurance: 0,
      scm: 0,
      balance: 96786.01
    },
    {
      index: 3,
      payment: 1730.99,
      principal: 1610.01,
      interest: 120.98,
      insurance: 0,
      scm: 0,
      balance: 95176
    },
    {
      index: 4,
      payment: 1730.99,
      principal: 1612.02,
      interest: 118.97,
      insurance: 0,
      scm: 0,
      balance: 93563.98
    },
    {
      index: 5,
      payment: 1730.99,
      principal: 1614.04,
      interest: 116.95,
      insurance: 0,
      scm: 0,
      balance: 91949.94
    },
    {
      index: 6,
      payment: 1730.99,
      principal: 1616.05,
      interest: 114.94,
      insurance: 0,
      scm: 0,
      balance: 90333.89
    },
    {
      index: 7,
      payment: 1730.99,
      principal: 1618.07,
      interest: 112.92,
      insurance: 0,
      scm: 0,
      balance: 88715.82
    },
    {
      index: 8,
      payment: 1730.99,
      principal: 1620.1,
      interest: 110.89,
      insurance: 0,
      scm: 0,
      balance: 87095.72
    },
    {
      index: 9,
      payment: 1730.99,
      principal: 1622.12,
      interest: 108.87,
      insurance: 0,
      scm: 0,
      balance: 85473.6
    },
    {
      index: 10,
      payment: 1730.99,
      principal: 1624.15,
      interest: 106.84,
      insurance: 0,
      scm: 0,
      balance: 83849.45
    },
    {
      index: 11,
      payment: 1730.99,
      principal: 1626.18,
      interest: 104.81,
      insurance: 0,
      scm: 0,
      balance: 82223.27
    },
    {
      index: 12,
      payment: 1730.99,
      principal: 1628.21,
      interest: 102.78,
      insurance: 0,
      scm: 0,
      balance: 80595.06
    },
    {
      index: 13,
      payment: 1730.99,
      principal: 1630.25,
      interest: 100.74,
      insurance: 0,
      scm: 0,
      balance: 78964.81
    },
    {
      index: 14,
      payment: 1730.99,
      principal: 1632.28,
      interest: 98.71,
      insurance: 0,
      scm: 0,
      balance: 77332.53
    },
    {
      index: 15,
      payment: 1730.99,
      principal: 1634.32,
      interest: 96.67,
      insurance: 0,
      scm: 0,
      balance: 75698.21
    },
    {
      index: 16,
      payment: 1730.99,
      principal: 1636.37,
      interest: 94.62,
      insurance: 0,
      scm: 0,
      balance: 74061.84
    },
    {
      index: 17,
      payment: 1730.99,
      principal: 1638.41,
      interest: 92.58,
      insurance: 0,
      scm: 0,
      balance: 72423.43
    },
    {
      index: 18,
      payment: 1730.99,
      principal: 1640.46,
      interest: 90.53,
      insurance: 0,
      scm: 0,
      balance: 70782.97
    },
    {
      index: 19,
      payment: 1730.99,
      principal: 1642.51,
      interest: 88.48,
      insurance: 0,
      scm: 0,
      balance: 69140.46
    },
    {
      index: 20,
      payment: 1730.99,
      principal: 1644.56,
      interest: 86.43,
      insurance: 0,
      scm: 0,
      balance: 67495.9
    },
    {
      index: 21,
      payment: 1730.99,
      principal: 1646.62,
      interest: 84.37,
      insurance: 0,
      scm: 0,
      balance: 65849.28
    },
    {
      index: 22,
      payment: 1730.99,
      principal: 1648.68,
      interest: 82.31,
      insurance: 0,
      scm: 0,
      balance: 64200.6
    },
    {
      index: 23,
      payment: 1730.99,
      principal: 1650.74,
      interest: 80.25,
      insurance: 0,
      scm: 0,
      balance: 62549.86
    },
    {
      index: 24,
      payment: 1730.99,
      principal: 1652.8,
      interest: 78.19,
      insurance: 0,
      scm: 0,
      balance: 60897.06
    },
    {
      index: 25,
      payment: 1730.99,
      principal: 1654.87,
      interest: 76.12,
      insurance: 0,
      scm: 0,
      balance: 59242.19
    },
    {
      index: 26,
      payment: 1730.99,
      principal: 1656.94,
      interest: 74.05,
      insurance: 0,
      scm: 0,
      balance: 57585.25
    },
    {
      index: 27,
      payment: 1730.99,
      principal: 1659.01,
      interest: 71.98,
      insurance: 0,
      scm: 0,
      balance: 55926.24
    },
    {
      index: 28,
      payment: 1730.99,
      principal: 1661.08,
      interest: 69.91,
      insurance: 0,
      scm: 0,
      balance: 54265.16
    },
    {
      index: 29,
      payment: 1730.99,
      principal: 1663.16,
      interest: 67.83,
      insurance: 0,
      scm: 0,
      balance: 52602
    },
    {
      index: 30,
      payment: 1730.99,
      principal: 1665.24,
      interest: 65.75,
      insurance: 0,
      scm: 0,
      balance: 50936.76
    },
    {
      index: 31,
      payment: 1730.99,
      principal: 1667.32,
      interest: 63.67,
      insurance: 0,
      scm: 0,
      balance: 49269.44
    },
    {
      index: 32,
      payment: 1730.99,
      principal: 1669.4,
      interest: 61.59,
      insurance: 0,
      scm: 0,
      balance: 47600.04
    },
    {
      index: 33,
      payment: 1730.99,
      principal: 1671.49,
      interest: 59.5,
      insurance: 0,
      scm: 0,
      balance: 45928.55
    },
    {
      index: 34,
      payment: 1730.99,
      principal: 1673.58,
      interest: 57.41,
      insurance: 0,
      scm: 0,
      balance: 44254.97
    },
    {
      index: 35,
      payment: 1730.99,
      principal: 1675.67,
      interest: 55.32,
      insurance: 0,
      scm: 0,
      balance: 42579.3
    },
    {
      index: 36,
      payment: 1730.99,
      principal: 1677.77,
      interest: 53.22,
      insurance: 0,
      scm: 0,
      balance: 40901.53
    },
    {
      index: 37,
      payment: 1730.99,
      principal: 1679.86,
      interest: 51.13,
      insurance: 0,
      scm: 0,
      balance: 39221.67
    },
    {
      index: 38,
      payment: 1730.99,
      principal: 1681.96,
      interest: 49.03,
      insurance: 0,
      scm: 0,
      balance: 37539.71
    },
    {
      index: 39,
      payment: 1730.99,
      principal: 1684.07,
      interest: 46.92,
      insurance: 0,
      scm: 0,
      balance: 35855.64
    },
    {
      index: 40,
      payment: 1730.99,
      principal: 1686.17,
      interest: 44.82,
      insurance: 0,
      scm: 0,
      balance: 34169.47
    },
    {
      index: 41,
      payment: 1730.99,
      principal: 1688.28,
      interest: 42.71,
      insurance: 0,
      scm: 0,
      balance: 32481.19
    },
    {
      index: 42,
      payment: 1730.99,
      principal: 1690.39,
      interest: 40.6,
      insurance: 0,
      scm: 0,
      balance: 30790.8
    },
    {
      index: 43,
      payment: 1730.99,
      principal: 1692.5,
      interest: 38.49,
      insurance: 0,
      scm: 0,
      balance: 29098.3
    },
    {
      index: 44,
      payment: 1730.99,
      principal: 1694.62,
      interest: 36.37,
      insurance: 0,
      scm: 0,
      balance: 27403.68
    },
    {
      index: 45,
      payment: 1730.99,
      principal: 1696.74,
      interest: 34.25,
      insurance: 0,
      scm: 0,
      balance: 25706.94
    },
    {
      index: 46,
      payment: 1730.99,
      principal: 1698.86,
      interest: 32.13,
      insurance: 0,
      scm: 0,
      balance: 24008.08
    },
    {
      index: 47,
      payment: 1730.99,
      principal: 1700.98,
      interest: 30.01,
      insurance: 0,
      scm: 0,
      balance: 22307.1
    },
    {
      index: 48,
      payment: 1730.99,
      principal: 1703.11,
      interest: 27.88,
      insurance: 0,
      scm: 0,
      balance: 20603.99
    },
    {
      index: 49,
      payment: 1730.99,
      principal: 1705.24,
      interest: 25.75,
      insurance: 0,
      scm: 0,
      balance: 18898.75
    },
    {
      index: 50,
      payment: 1730.99,
      principal: 1707.37,
      interest: 23.62,
      insurance: 0,
      scm: 0,
      balance: 17191.38
    },
    {
      index: 51,
      payment: 1730.99,
      principal: 1709.5,
      interest: 21.49,
      insurance: 0,
      scm: 0,
      balance: 15481.88
    },
    {
      index: 52,
      payment: 1730.99,
      principal: 1711.64,
      interest: 19.35,
      insurance: 0,
      scm: 0,
      balance: 13770.24
    },
    {
      index: 53,
      payment: 1730.99,
      principal: 1713.78,
      interest: 17.21,
      insurance: 0,
      scm: 0,
      balance: 12056.46
    },
    {
      index: 54,
      payment: 1730.99,
      principal: 1715.92,
      interest: 15.07,
      insurance: 0,
      scm: 0,
      balance: 10340.54
    },
    {
      index: 55,
      payment: 1730.99,
      principal: 1718.06,
      interest: 12.93,
      insurance: 0,
      scm: 0,
      balance: 8622.48
    },
    {
      index: 56,
      payment: 1730.99,
      principal: 1720.21,
      interest: 10.78,
      insurance: 0,
      scm: 0,
      balance: 6902.27
    },
    {
      index: 57,
      payment: 1730.99,
      principal: 1722.36,
      interest: 8.63,
      insurance: 0,
      scm: 0,
      balance: 5179.91
    },
    {
      index: 58,
      payment: 1730.99,
      principal: 1724.52,
      interest: 6.47,
      insurance: 0,
      scm: 0,
      balance: 3455.39
    },
    {
      index: 59,
      payment: 1730.99,
      principal: 1726.67,
      interest: 4.32,
      insurance: 0,
      scm: 0,
      balance: 1728.72
    },
    {
      index: 60,
      payment: 1730.88,
      principal: 1728.72,
      interest: 2.16,
      insurance: 0,
      scm: 0,
      balance: 0
    }
  ]
}