import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SimulatorFormComponent } from './simulator-form/simulator-form.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
  { path: 'welcome', component: WelcomeComponent},
  { path: 'simulator', component: SimulatorFormComponent},
  { path: 'simulator/:view', component: SimulatorFormComponent},
  { path: '', redirectTo: 'welcome', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      // { enableTracing: true } // <-- debugging purposes only
    ),
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }
