export default {
  id: 'loan 8',
  ci: 200000.00,
  crd: 200000.00,
  ti: 1.8,
  ta: 0.237,
  inMode: 'crd',
  dateFin: null,
  mois: 20,
  totalPayments: 203583.92,
  coutTotal: 3583.92,
  data:[
  ]
}